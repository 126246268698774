import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  NAME_OF_COMMON_SLICE,
  GET_GENDER,
  GET_ORGANIZATION_GRADE_LEVEL,
} from "constants/AppConstants";
import { GET_STUDENT_PRE_LOADED_DATA } from "constants/common/index";
import {
  GET_ALL_TEACHERS_HAVING_USERS
} from 'constants/budget-allocation/index.js';
import CommonService from "services/CommonService/CommonService";
export const initialState = {
  GenderResult: [],
  OrganizationGradeLevelResult: [],
  errors: {},
  showMessage: null,

  preLoadedStudentLoading: false,
  preLoadedStudentData: null,
  teachersHavingUser: [],
};

export const getTeachersHaveUser = createAsyncThunk("getTeachersHaveUser", async (data) => {
  try {
    const response = await CommonService.postData(data, GET_ALL_TEACHERS_HAVING_USERS);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); 
  }
})
export const getGenders = createAsyncThunk("getGenders", async (data) => {
  try {
    const response = await CommonService.getData(data, GET_GENDER);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const getOrganizationGradeLevel = createAsyncThunk("getOrganizationGradeLevel", async (data) => {
  try {
    const response = await CommonService.getData(data, GET_ORGANIZATION_GRADE_LEVEL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const getStudentPreLoadedData = createAsyncThunk("getStudentPreLoadedData", async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_PRE_LOADED_DATA);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
})
//TODO:: this is same request as above (getStudentPreLoadedData) but in this the loading variable still false
//TODO:: need to optimize and refactor it this is when guardian save in student side 
//TODO:: load all guardians but loading variable still will false 
export const getNewlyAddedGuardians = createAsyncThunk("getNewlyAddedGuardians", async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_PRE_LOADED_DATA);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
})

export const commonSlice = createSlice({
  name: NAME_OF_COMMON_SLICE,
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeachersHaveUser.pending, (state, action) => {        
        state.errors = {}
        state.showMessage = false;
        state.teachersHavingUser = [];
      }).addCase(getTeachersHaveUser.fulfilled, (state, action) => {
        state.teachersHavingUser = action.payload;
        state.errors = {}
      }).addCase(getTeachersHaveUser.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); 
        state.showMessage = true;
      })
      .addCase(getGenders.pending, (state, action) => {
      }).addCase(getGenders.fulfilled, (state, action) => {
        state.GenderResult = action.payload;
        state.errors = {}
      }).addCase(getGenders.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
      })
      .addCase(getOrganizationGradeLevel.pending, (state, action) => {
      })
      .addCase(getOrganizationGradeLevel.fulfilled, (state, action) => {
        state.OrganizationGradeLevelResult = action.payload;
        state.errors = {}
      })
      .addCase(getOrganizationGradeLevel.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
      })
      .addCase(getStudentPreLoadedData.pending, (state, action) => {
        state.preLoadedStudentLoading = true
      })
      .addCase(getStudentPreLoadedData.fulfilled, (state, action) => {
        state.preLoadedStudentLoading = false
        state.preLoadedStudentData = action.payload.data;
        state.errors = {}
      })
      .addCase(getStudentPreLoadedData.rejected, (state, action) => {
        state.preLoadedStudentLoading = false
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
      })
      .addCase(getNewlyAddedGuardians.pending, (state, action) => {

      }).addCase(getNewlyAddedGuardians.fulfilled, (state, action) => {
        state.preLoadedStudentLoading = false
        state.preLoadedStudentData = action.payload.data
        state.errors = {}
      }).addCase(getNewlyAddedGuardians.rejected, (state, action) => {
        state.preLoadedStudentLoading = false
        state.errors = JSON.parse(action.error.message)
        state.showMessage = true
      });


  },
});

export default commonSlice.reducer;