import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  SYSTEM_LOGS_SLICE,
  GET_LOGS
} from 'constants/logs/index.js';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  logsTableLoading: false,
  selectedType: null,
  dateRange: {},
  logsData: [],
};
export const getLogs = createAsyncThunk("getLogs", async (data) => {
    try {
      return await CommonService.getData(data, GET_LOGS);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});

export const manageSystemLogsSlice = createSlice({
  name: SYSTEM_LOGS_SLICE,
  initialState,
  reducers: {
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;      
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogs.pending, (state, action) => {
        state.logsTableLoading = true;
        state.logsData = [];
      }).addCase(getLogs.fulfilled, (state, action) => {
        state.logsTableLoading = false;
        state.logsData = action.payload.data;
      }).addCase(getLogs.rejected, (state, action) => {
        state.logsTableLoading = false;
      });
  },
});

export const { 
  setSelectedType, 
  setDateRange
} = manageSystemLogsSlice.actions;

export default manageSystemLogsSlice.reducer;
