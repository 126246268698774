import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_PERMISSION, NAME_OF_PERMISSION_SLICE, GET_PERMISSION, DELETE_PERMISSION } from 'constants/AppConstants';
import PermissionService from 'services/Permissions/PermissionService';
import IntlMessage from "components/util-components/IntlMessage";
import CommonService from 'services/CommonService/CommonService';
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

export const initialState = {
  errors: {},
  showMessage: '',
  permissionResult: [],
  permissionButtonSpinner: false,
  permissionTableLoading: true,
  AddPermissionDrawer: false,
  editPermissionData: [],
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  modalLabel: setLocale('permissions.add'),
  DrawerStatus: 0
};
export const createPermission = createAsyncThunk(
  CREATE_PERMISSION,
  async (data) => {
    try {
      const response = await PermissionService.createPermission(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getPermission = createAsyncThunk(
  "getPermission",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_PERMISSION);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deletePermission = createAsyncThunk(
  DELETE_PERMISSION,
  async (data) => {
    try {
      const response = await PermissionService.deletePermission(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const managePermissionSlice = createSlice({
  name: NAME_OF_PERMISSION_SLICE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.errors = {};
    },
    AddPermissionDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.permissionButtonSpinner = false;
      }
      state.DrawerStatus = action.payload.errorStatus;
      state.AddPermissionDrawer = action.payload.status;
      state.editPermissionData = [];
      state.modalLabel = setLocale('permissions.add');
    },
    EditPermission: (state, action) => {
      state.AddPermissionDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.editPermissionData = action.payload.data;
      state.modalLabel = setLocale('permissions.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPermission.pending, (state, action) => {
        state.DrawerStatus = 0
        state.permissionButtonSpinner = true;
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.permissionButtonSpinner = false;
        state.errors = {};
      })
      .addCase(createPermission.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
        state.permissionButtonSpinner = false;
      })
      .addCase(getPermission.pending, (state, action) => {
        state.permissionTableLoading = true;
      })
      .addCase(getPermission.fulfilled, (state, action) => {
        state.permissionTableLoading = false;
        state.permissionResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getPermission.rejected, (state, action) => {
        state.permissionTableLoading = true;
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true;
      })
      .addCase(deletePermission.pending, (state, action) => {
      })
      .addCase(deletePermission.fulfilled, (state, action) => {
      })
      .addCase(deletePermission.rejected, (state, action) => {

      });
  },
});

export const { onCloseError, setColumnSearch, updateSortFilters, AddPermissionDrawerStatus, EditPermission, CancelEditPermission } = managePermissionSlice.actions;
export default managePermissionSlice.reducer;  