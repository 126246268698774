import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AUTH_TOKEN,
  DASHBOARD_NAV_TREE,
  USER_INFORMATION,
  PROTECTED_ROUTE,
  USER_ALL_INFORMATION_ORGANIZATION_WISE,
  EMAIL,
  USER_ROLE,
  DEFAULT_ROUTE,
  IMPERSONATE,
  VERSION,
  DEFAULT_YEAR
} from "constants/AuthConstant";
import {
  APP_VERSION
} from 'constants/ApiConstant';
import AuthService from "services/AuthService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,


  notificationData: [],
  user: '',
  modulePermissions: [],
  verifyDeviceModal: false,
  resendDisabled: false,
  countdownTimer: 60,

};


export const signIn = createAsyncThunk('userLogin', async (data, { rejectWithValue, dispatch, thunkAPI }) => {
  const { email, password } = data
  try {
    const response = await AuthService.login({ email, password, app_id: 1 })
    if (response?.code) {
      dispatch(verificationCode())
    }
    if (response?.user) {
      const token = response.access_token;
      /**
       * set local storage 
       * @var
       * @USER
       */
      dispatch(setLocalStorageUser(response))
      return token;
    }
  } catch (err) {
    throw err?.response?.data?.data?.message;
  }
})
/**
 * this is Login
 * As User
 */
export const loginAsUser = createAsyncThunk('loginAsUser', async (data, { dispatch }) => {
  data.app_id = 1;
  try {
    const response = await AuthService.loginAsUser(data)
    if (response?.user) {
      const token = response.access_token;
      /** * set local storage  */
      dispatch(setLocalStorageUser(response))
      return token;
    }
  } catch (err) {
    throw err?.response?.data?.data?.message;
  }
})
export const leaveImpersonateUser = createAsyncThunk('leaveImpersonateUser', async (data, { dispatch }) => {
  data.app_id = 1;
  try {
    const response = await AuthService.leaveImpersonateUser(data)
    if (response?.user) {
      const token = response.access_token;
      /** * set local storage  */
      dispatch(setLocalStorageUser(response))
      return token;
    }
  } catch (err) {
    throw err?.response?.data?.data?.message;
  }
})

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    const app_id = data.app_id = 1;

    try {
      const response = await AuthService.signUpEmailRequest(email, password, app_id);
      if (response.user) {
        const token = response.user.refreshToken;
        localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
        return token;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, { rejectWithValue }) => {
    const { email } = data;
    // console.log(email,'forgot-email')
    try {
      const response = await AuthService.forgotPassword({ email });
      if (response.data) {
        localStorage.setItem(EMAIL, response.data.email);
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, { rejectWithValue }) => {
    const { confirm_password, password, token, email } = data;
    try {
      const response = await AuthService.resetPassword({
        password,
        confirm_password,
        token,
        email,
      });
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const response = await AuthService.signOutRequest();
  localStorage.clear();
  return response.data;
});

export const verifyUserDevice = createAsyncThunk('verifyUserDevice', async (data, { dispatch }) => {
  data.app_id = 1;
  try {
    const response = await AuthService.verifyUserDevice(data)
    if (response?.user) {
      dispatch(removeDeviceModal(false))
      const token = response.access_token;
      localStorage.setItem(AUTH_TOKEN, response.access_token);
      localStorage.setItem(USER_INFORMATION, JSON.stringify(response.user));
      localStorage.setItem(USER_ALL_INFORMATION_ORGANIZATION_WISE, JSON.stringify(response.user_all_informations));
      localStorage.setItem(DEFAULT_ROUTE, JSON.stringify(response.defaultRoute));
      localStorage.setItem(DASHBOARD_NAV_TREE, JSON.stringify(response.dashBoardNavTree));
      localStorage.setItem(USER_ROLE, JSON.stringify(response.userRole));
      localStorage.setItem(PROTECTED_ROUTE, JSON.stringify(response.protectedRoutes));
      localStorage.setItem(DEFAULT_YEAR, JSON.stringify(response.defaultYear));
      localStorage.setItem(VERSION, APP_VERSION);
      window.location.reload(true);
      return token;
    }
  } catch (err) {
    throw err?.response?.data?.data?.message;
  }
})
/** resend Code  */
export const resendCode = createAsyncThunk('resendCode', async (data) => {
  try {
    await AuthService.resendCode(data)
  } catch (err) {
    throw err?.response?.data?.data?.message;
  }
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLocalStorageUser: (state, action) => {
      localStorage.clear();
      localStorage.setItem(AUTH_TOKEN, action.payload.access_token);
      localStorage.setItem(DEFAULT_ROUTE, JSON.stringify(action.payload.defaultRoute));
      localStorage.setItem(USER_INFORMATION, JSON.stringify(action.payload.user));
      localStorage.setItem(USER_ALL_INFORMATION_ORGANIZATION_WISE, JSON.stringify(action.payload.user_all_informations));
      localStorage.setItem(DASHBOARD_NAV_TREE, JSON.stringify(action.payload.dashBoardNavTree));
      localStorage.setItem(USER_ROLE, JSON.stringify(action.payload.userRole));
      localStorage.setItem(PROTECTED_ROUTE, JSON.stringify(action.payload.protectedRoutes));
      localStorage.setItem(DEFAULT_YEAR, JSON.stringify(action.payload.defaultYear));
      localStorage.setItem(IMPERSONATE, JSON.stringify(action.payload.impersonate));
      localStorage.setItem(VERSION, APP_VERSION);
    },
    removeDeviceModal: (state, action) => {
      state.verifyDeviceModal = action.payload
    },
    verificationCode: (state, action) => {
      state.verifyDeviceModal = true
      state.resendDisabled = true
    },
    setResendDisabled: (state, action) => {
      state.resendDisabled = action.payload
    },
    updateCountDownTimer: (state, action) => {
      state.countdownTimer = action.payload
    },
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })


      .addCase(verifyUserDevice.pending, (state) => {
        state.loading = true
      })
      .addCase(verifyUserDevice.fulfilled, (state, action) => {
        state.loading = false
        state.message = ''
        state.redirect = '/'
        state.token = action.payload
      })
      .addCase(verifyUserDevice.rejected, (state, action) => {
        state.message = action.error.message
        state.showMessage = true
        state.loading = false
      })
  },
});

export const changeOrganization = createAsyncThunk(
  "changeOrganization",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await AuthService.changeUserOrga({ id, app_id: 1 });
      if (response.user) {
        const token = response.access_token;
        localStorage.setItem(AUTH_TOKEN, response.access_token);
        localStorage.setItem(USER_INFORMATION, JSON.stringify(response.user));
        localStorage.setItem(USER_ALL_INFORMATION_ORGANIZATION_WISE, JSON.stringify(response.user_all_informations));
        localStorage.setItem(DEFAULT_ROUTE, JSON.stringify(response.defaultRoute));
        localStorage.setItem(DASHBOARD_NAV_TREE, JSON.stringify(response.dashBoardNavTree));
        localStorage.setItem(USER_ROLE, JSON.stringify(response.userRole));
        localStorage.setItem(PROTECTED_ROUTE, JSON.stringify(response.protectedRoutes));
        localStorage.setItem(DEFAULT_YEAR, JSON.stringify(response.defaultYear));
        localStorage.setItem(VERSION, APP_VERSION);
        window.location.reload(true);
        return token;
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const {
  removeDeviceModal,
  verificationCode,
  setResendDisabled,
  updateCountDownTimer,

  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setLocalStorageUser
} = authSlice.actions;

export default authSlice.reducer;
