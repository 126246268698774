import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CHARGES_RATES, CHARGES_RATES_API_URL, GET_ALL_CHARGES_RATES_API_URL, GET_CHARGE_RATE_EXIST_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ChargesRatesErrors: {},
  permission: permission?.charges_rates ?? [],
  ChargesRatesShowMessage: null,
  ChargesRatesResult: [],
  ChargesRatesButtonSpinner: false,
  ChargesRatesTableLoading: true,
  ChargesRatesAddDrawer: false,
  ChargesRatesEditData: {},
  ChargeRateExistData: [],
  ChargesRatesButtonAndModelLabel: setLocale('charges_and_invoices.charge_add_rate'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {
    "amount": '',
    'charge_type': '',
    'effective_from': '',
    'effective_to': '',
    'grade_level': '',
    'school_year': '',
    'is_active': ''
  },
  ViewChargesRatesData: [],
  ViewChargesRatesLoader: true,
  StudentButtonDisable: true,
   DrawerStatus: 0,
};

export const createChargesRates = createAsyncThunk(
  "createChargesRates",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CHARGES_RATES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getChargesRates = createAsyncThunk(
  "getChargesRates",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CHARGES_RATES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewChargesRates = createAsyncThunk(
  "viewChargesRates",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CHARGES_RATES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteChargesRates = createAsyncThunk(
  "deleteChargesRates",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CHARGES_RATES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getChargesRateExist = createAsyncThunk(
  "getChargesRateExist",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_CHARGE_RATE_EXIST_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageChargesRatesSlice = createSlice({
  name: CHARGES_RATES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ChargesRatesErrors = {};
    },
    ChargesRatesAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.ChargesRatesButtonSpinner = false;
      }
      // state.ChargesRatesErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ChargesRatesAddDrawer = action.payload.status;
      state.ChargesRatesEditData = {};
      state.StudentButtonDisable = true;
      state.ChargesRatesButtonAndModelLabel = setLocale('charges_and_invoices.charge_add_rate');
    },
    ChargesRatesEditWithDrawerStatus: (state, action) => {
      // state.ChargesRatesErrors = {};
      state.ChargesRatesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ChargesRatesEditData = action.payload.data;
      state.ChargesRatesButtonAndModelLabel = setLocale('charges_and_invoices.charge_update_rate');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setStudentButtonDisable: (state, action) => {
      state.StudentButtonDisable = action.payload;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChargesRates.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ChargesRatesButtonSpinner = true;
      }).addCase(createChargesRates.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ChargesRatesButtonSpinner = false;
        state.ChargesRatesErrors = {}
      }).addCase(createChargesRates.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ChargesRatesShowMessage = true;
        state.ChargesRatesButtonSpinner = false;
        state.ChargesRatesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getChargesRates.pending, (state, action) => {
        state.ChargesRatesButtonSpinner = true;
        state.ChargesRatesTableLoading = true;

        state.StudentButtonDisable = true;
      })
      .addCase(getChargesRates.fulfilled, (state, action) => {
        state.ChargesRatesButtonSpinner = false;
        state.ChargesRatesTableLoading = false;

        state.StudentButtonDisable = action.payload?.data.length > 0 ? false : true;

        state.ChargesRatesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getChargesRates.rejected, (state, action) => {
        state.ChargesRatesShowMessage = true; // Set the showMessage flag to display the errors
        state.ChargesRatesButtonSpinner = false;
        state.ChargesRatesTableLoading = false;
        state.StudentButtonDisable = true;

        state.ChargesRatesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getChargesRateExist.pending, (state, action) => {
      })
      .addCase(getChargesRateExist.fulfilled, (state, action) => {
        state.ChargeRateExistData = action.payload;
      })
      .addCase(getChargesRateExist.rejected, (state, action) => {
      })

      .addCase(viewChargesRates.pending, (state, action) => {
        state.ViewChargesRatesLoader = true;
        state.ViewChargesRatesData = [];
      }).addCase(viewChargesRates.fulfilled, (state, action) => {
        state.ViewChargesRatesLoader = false;
        state.ViewChargesRatesData = action.payload;
      }).addCase(viewChargesRates.rejected, (state, action) => {
        state.ViewChargesRatesLoader = false;
        state.ViewChargesRatesData = [];
      })
      .addCase(deleteChargesRates.pending, (state, action) => {

      }).addCase(deleteChargesRates.fulfilled, (state, action) => {

      }).addCase(deleteChargesRates.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, setStudentButtonDisable, ChargesRatesAddDrawerStatus, ChargesRatesEditWithDrawerStatus, updateSortFilters } = manageChargesRatesSlice.actions;

export default manageChargesRatesSlice.reducer;
