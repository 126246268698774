import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {COMMUNICATION, COMMUNICATION_API_URL, GET_ALL_COMMUNICATION_API_URL,SENT_COMMUNICATION_MAIL_API_URL,GET_COMMUNICATION_MAIL_API_URL,DELETE_COMMUNICATION_MAIL_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  CommunicationErrors       : {},
  permission: permission?.communication ?? [],
  CommunicationShowMessage  : null,
  CommunicationResult       : [],
  CommunicationButtonSpinner : false,
  CommunicationTableLoading : true,
  CommunicationAddDrawer    : false,
  CommunicationEditData     : [],
  CommunicationButtonAndModelLabel  : setLocale('communication.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewCommunicationData: [],
    ViewCommunicationLoader: true,
    getCommunicationMailResult: []
};

export const createCommunication = createAsyncThunk(
    "createCommunication",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, COMMUNICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const sentCommunicationMail = createAsyncThunk(
    "sentCommunicationMail",
  async (data) => {
    try {
      const response = await CommonService.postData(data, SENT_COMMUNICATION_MAIL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCommunicationMail = createAsyncThunk(
  "getCommunicationMail",
async (data) => {
  try {
    const response = await CommonService.getAllPost(data, GET_COMMUNICATION_MAIL_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
);

export const getCommunication = createAsyncThunk(
    "getCommunication",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_COMMUNICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewCommunication = createAsyncThunk(
  "viewCommunication",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, COMMUNICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteCommunication = createAsyncThunk(
  "deleteCommunication",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, COMMUNICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const deleteCommunicationMail = createAsyncThunk(
  "deleteCommunicationMail",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, DELETE_COMMUNICATION_MAIL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageCommunicationSlice = createSlice({
  name: COMMUNICATION,
  initialState,
  reducers: {
    CommunicationAddDrawerStatus: (state, action) => {
      state.CommunicationErrors      = {};
      state.CommunicationAddDrawer   = action.payload;
      state.CommunicationEditData    = [];
      state.CommunicationButtonAndModelLabel = setLocale('communication.add');
    },
    CommunicationEditWithDrawerStatus: (state, action) => {
      state.CommunicationErrors = {};
      state.CommunicationAddDrawer = true;
      state.CommunicationEditData = action.payload;
      state.CommunicationButtonAndModelLabel = setLocale('communication.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCommunication.pending, (state, action) => {
        state.CommunicationButtonSpinner = true;
      }).addCase(createCommunication.fulfilled, (state, action) => {
        state.CommunicationButtonSpinner = false;
        state.CommunicationErrors = {}
      }).addCase(createCommunication.rejected, (state, action) => {
        state.CommunicationShowMessage = true;
        state.CommunicationButtonSpinner = false;
        state.CommunicationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(sentCommunicationMail.pending, (state, action) => {
        state.CommunicationButtonSpinner = true;
      }).addCase(sentCommunicationMail.fulfilled, (state, action) => {
        state.CommunicationButtonSpinner = false;
        state.CommunicationErrors = {}
      }).addCase(sentCommunicationMail.rejected, (state, action) => {
        state.CommunicationButtonSpinner = false;
      })
      .addCase(getCommunication.pending, (state, action) => {
        state.CommunicationButtonSpinner = true;
        state.CommunicationTableLoading = true;
      }).addCase(getCommunication.fulfilled, (state, action) => {
        state.CommunicationButtonSpinner = false;
        state.CommunicationTableLoading = false;
        state.CommunicationResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getCommunication.rejected, (state, action) => {
        state.CommunicationShowMessage = true; // Set the showMessage flag to display the errors
        state.CommunicationButtonSpinner = false;
        state.CommunicationTableLoading = false;
        state.CommunicationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getCommunicationMail.pending, (state, action) => {
        state.CommunicationButtonSpinner = true;
        state.CommunicationTableLoading = true;
      }).addCase(getCommunicationMail.fulfilled, (state, action) => {
        state.CommunicationButtonSpinner = false;
        state.CommunicationTableLoading = false;
        state.getCommunicationMailResult = action.payload.data;
        // state.tablePagination = {
        //     ...state.tablePagination,
        //     total: action.payload.total,
        //     current: action.payload.current_page,
        //     pageSize: action.payload.per_page
        // };
      }).addCase(getCommunicationMail.rejected, (state, action) => {
        state.CommunicationShowMessage = true; // Set the showMessage flag to display the errors
        state.CommunicationButtonSpinner = false;
        state.CommunicationTableLoading = false;
        state.CommunicationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewCommunication.pending, (state, action) => {
        state.ViewCommunicationLoader = true;
        state.ViewCommunicationData = [];
      }).addCase(viewCommunication.fulfilled, (state, action) => {
        state.ViewCommunicationLoader = false;
        state.ViewCommunicationData = action.payload;
      }).addCase(viewCommunication.rejected, (state, action) => {
        state.ViewCommunicationLoader = false;
        state.ViewCommunicationData = [];
      })
      .addCase(deleteCommunication.pending, (state, action) => {

      }).addCase(deleteCommunication.fulfilled, (state, action) => {

      }).addCase(deleteCommunication.rejected, (state, action) => {

      })
      .addCase(deleteCommunicationMail.pending, (state, action) => {

      }).addCase(deleteCommunicationMail.fulfilled, (state, action) => {

      }).addCase(deleteCommunicationMail.rejected, (state, action) => {

      });
  },
});

export const {  CommunicationAddDrawerStatus, CommunicationEditWithDrawerStatus, updateSortFilters } = manageCommunicationSlice.actions;

export default manageCommunicationSlice.reducer;
