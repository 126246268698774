import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { INCIDENT_REPORT, INCIDENT_REPORT_API_URL, GET_ALL_INCIDENT_REPORT_API_URL, GET_PARTICIPANT_DATA_API_URL, DELETE_PARTICIPANT_API_URL, SAVE_PARTICIPANT_API_URL, SAVE_INCIDENT_ACTION_API_URL, GET_INCIDENT_ACTION_API_URL, SAVE_PARTICIPANT_ACTION_API_URL, SAVE_OTHER_PARTICIPANT_API_URL, GET_INCIDENT_DASHBOARD_DATA_API_URL, GET_INCIDENTS_DATA_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  IncidentReportErrors: {},
  permission: permission?.incident_report ?? [],
  IncidentReportShowMessage: null,
  IncidentReportResult: [],
  IncidentReportButtonSpinner: false,
  ParticipantButtonSpinner: false,
  ParticipantActionButtonSpinner: false,
  ActionButtonSpinner: false,
  otherParticipantButtonSpinner: false,
  IncidentReportTableLoading: true,
  incidentActionLoading: false,
  incidentDataLoading: false,
  IncidentReportAddDrawer: false,
  ViewIncidentReportLoader: true,
  participantTableLoading: false,
  IncidentFormStatus: true,
  IncidentParticipantFormStatus: false,
  IncidentParticipantActionFormStatus: false,
  IncidentParticipantStatus: false,
  IncidentReportEditData: {},
  IncidentReportButtonAndModelLabel: setLocale('save'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  dashboardFilters: {
    schoolYearId: null,
    gradeLevelId: null,
    classId: null,
    dateRange: null,
    organization_id: null,
    // actionId: null,
    // typeId: null,
  },
  ViewIncidentReportData: {},
  IncidentFormData: {},
  IncidentParticipantFormData: {},
  IncidentActionFormData: {},
  selectedRowKeys: [],
  selectedStudentsRowKeys: [],
  differencePageStudentRecord: [],
  differencePageUserRecord: [],
  participantData: [],
  incidentParticipantData: [],
  otherParticipant: [],
  incidentActions: [],
  IncidentsData: [],
  countData: [],
  ActionWise: [],
  CategoryWise: [],
  IncidentsForecast: [],
  LocationWise: [],
  RequireAttention: [],
  TimeFrame: [],
  incidentDashboardLoading: true,
  incidentLocationWiseLoading: false,
  incidentActionWiseLoading: false,
  incidentTypeWiseLoading: false,
  currentPageStudentRecord: [],
  currentPageUserRecord: [],
};

export const createIncidentReport = createAsyncThunk(
  "createIncidentReport",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, INCIDENT_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getIncidentReport = createAsyncThunk(
  "getIncidentReport",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_INCIDENT_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewIncidentReport = createAsyncThunk(
  "viewIncidentReport",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, INCIDENT_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteIncidentReport = createAsyncThunk(
  "deleteIncidentReport",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, INCIDENT_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getParticipantData = createAsyncThunk(
  "getParticipantData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_PARTICIPANT_DATA_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const saveParticipant = createAsyncThunk(
  "saveParticipant",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, SAVE_PARTICIPANT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const saveIncidentAction = createAsyncThunk(
  "saveIncidentAction",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, SAVE_INCIDENT_ACTION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getIncidentActions = createAsyncThunk(
  "getIncidentActions",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INCIDENT_ACTION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getIncidentsData = createAsyncThunk(
  "getIncidentsData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INCIDENTS_DATA_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const saveParticipantAction = createAsyncThunk(
  "saveParticipantAction",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, SAVE_PARTICIPANT_ACTION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const saveOtherParticipant = createAsyncThunk(
  "saveOtherParticipant",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, SAVE_OTHER_PARTICIPANT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteParticipant = createAsyncThunk(
  "deleteParticipant",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, DELETE_PARTICIPANT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getIncidentDashboardData = createAsyncThunk(
  "getIncidentDashboardData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INCIDENT_DASHBOARD_DATA_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getLocationWise = createAsyncThunk(
  "getLocationWise",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INCIDENT_DASHBOARD_DATA_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCategoryWise = createAsyncThunk(
  "getCategoryWise",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INCIDENT_DASHBOARD_DATA_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getActionWise = createAsyncThunk(
  "getActionWise",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INCIDENT_DASHBOARD_DATA_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageIncidentReportSlice = createSlice({
  name: INCIDENT_REPORT,
  initialState,
  reducers: {
    setStateClear: (state, action) => {
      state.IncidentReportEditData = {};
      state.otherParticipant = [];
      state.incidentActions = [];
      state.selectedRowKeys = [];
      state.selectedStudentsRowKeys = [];
      state.differencePageStudentRecord = [];
      state.differencePageUserRecord = [];
      state.participantData = [];
      state.incidentParticipantData = [];
      state.ViewIncidentReportData = {};
      state.IncidentFormData = {};
      state.IncidentParticipantFormData = {};
      state.IncidentActionFormData = {};
      state.IncidentReportEditData = {};

      state.IncidentReportButtonSpinner = false;
      state.ParticipantButtonSpinner = false;
      state.ParticipantActionButtonSpinner = false;
      state.ActionButtonSpinner = false;
      state.otherParticipantButtonSpinner = false;
      state.IncidentReportTableLoading = true;
      state.incidentActionLoading = false;
      state.IncidentReportAddDrawer = false;
      state.ViewIncidentReportLoader = true;
      state.participantTableLoading = false;
      state.IncidentFormStatus = true;
      state.IncidentParticipantFormStatus = false;
      state.IncidentParticipantActionFormStatus = false;
      state.IncidentParticipantStatus = false;
    },
    IncidentReportAddDrawerStatus: (state, action) => {
      state.IncidentReportErrors = {};
      state.IncidentReportAddDrawer = action.payload;
      state.IncidentFormStatus = true;
      state.IncidentParticipantFormStatus = false;
      state.IncidentParticipantActionFormStatus = false;
      state.IncidentParticipantStatus = false;
      state.IncidentReportEditData = {};
      state.IncidentReportButtonAndModelLabel = setLocale('save');
    },
    IncidentReportEditWithDrawerStatus: (state, action) => {
      state.IncidentReportErrors = {};
      state.IncidentReportAddDrawer = true;
      state.IncidentReportEditData = action.payload;
      state.IncidentReportButtonAndModelLabel = setLocale('save');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    updateDashboardFilters: (state, action) => {
      state.dashboardFilters = action.payload;
    },
    setIncidentFormStatus: (state, action) => {
      state.IncidentFormStatus = action.payload;
    },
    setIncidentParticipantFormStatus: (state, action) => {
      state.IncidentParticipantFormStatus = action.payload;
    },
    setIncidentParticipantStatus: (state, action) => {
      state.IncidentParticipantStatus = action.payload;
    },
    setIncidentParticipantActionFormStatus: (state, action) => {
      state.IncidentParticipantActionFormStatus = action.payload;
    },
    setIncidentFormData: (state, action) => {
      state.IncidentFormData = action.payload;
    },
    setIncidentParticipantFormData: (state, action) => {
      state.IncidentParticipantFormData = action.payload;
    },
    setIncidentActionFormData: (state, action) => {
      state.IncidentActionFormData = action.payload;
    },
    setSelectedRowKeys: (state, action) => {
      if (state.differencePageUserRecord && state.differencePageUserRecord.length > 0) {
        state.selectedRowKeys = state.selectedRowKeys.filter(key => !state.differencePageUserRecord.includes(key));
      }
      state.selectedRowKeys = [...state.selectedRowKeys, ...action.payload.selected];
      state.selectedRowKeys = [...new Set(state.selectedRowKeys)];
      state.differencePageUserRecord = state.currentPageUserRecord.filter(key => !action.payload.selected.includes(key));
    },
    setStudentSelectedRowKeys: (state, action) => {
      if (state.differencePageStudentRecord && state.differencePageStudentRecord.length > 0) {
        state.selectedStudentsRowKeys = state.selectedStudentsRowKeys.filter(key => !state.differencePageStudentRecord.includes(key));
      }
      const selectedRowKeys = [...state.selectedStudentsRowKeys, ...action.payload.selected];
      state.selectedStudentsRowKeys = [...new Set(selectedRowKeys)];
      state.differencePageStudentRecord = state.currentPageStudentRecord.filter(key => !action.payload.selected.includes(key));
    },
    updateStudentSelectedRowKeys: (state, action) => {
      state.selectedStudentsRowKeys = action.payload.selected;
    },
    updateSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload.selected;
    },
    setCurrentPageStudentRecord: (state, action) => {
      state.currentPageStudentRecord = action.payload;
    },
    setCurrentPageUserRecord: (state, action) => {
      state.currentPageUserRecord = action.payload;
    },
    setOtherParticipantData: (state, action) => {
      const existingIndex = state.otherParticipant.findIndex(item => item.others_email === action.payload.others_email);
      if (action.payload.throughDelete) {
        // If the email exists, replace the existing data with the new data
        state.otherParticipant = action.payload.data;
      } else {
        // If the email doesn't exist, push the new data
        state.otherParticipant.push(action.payload.data);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createIncidentReport.pending, (state, action) => {
        state.IncidentReportButtonSpinner = true;
      }).addCase(createIncidentReport.fulfilled, (state, action) => {
        state.IncidentReportButtonSpinner = false;
        state.IncidentReportErrors = {}
      }).addCase(createIncidentReport.rejected, (state, action) => {
        state.IncidentReportShowMessage = true;
        state.IncidentReportButtonSpinner = false;
        state.IncidentReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getIncidentReport.pending, (state, action) => {
        state.IncidentReportButtonSpinner = true;
        state.IncidentReportTableLoading = true;
      }).addCase(getIncidentReport.fulfilled, (state, action) => {
        state.IncidentReportButtonSpinner = false;
        state.IncidentReportTableLoading = false;
        state.IncidentReportResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getIncidentReport.rejected, (state, action) => {
        state.IncidentReportShowMessage = true; // Set the showMessage flag to display the errors
        state.IncidentReportButtonSpinner = false;
        state.IncidentReportTableLoading = false;
        state.IncidentReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewIncidentReport.pending, (state, action) => {
        state.ViewIncidentReportLoader = true;
        state.ViewIncidentReportData = {};
        state.IncidentReportEditData = {};
      }).addCase(viewIncidentReport.fulfilled, (state, action) => {
        state.ViewIncidentReportLoader = false;

        state.participantData = {
          ...state.participantData,
          student: action.payload.incident_participant.student,
          staff: action.payload.incident_participant.staff,

        }
        state.selectedRowKeys = action.payload.incident_participant.user_ids;
        state.selectedStudentsRowKeys = action.payload.incident_participant?.student_ids;
        state.otherParticipant = action.payload?.incident_participant?.other;

        state.ViewIncidentReportData = action.payload;
        state.IncidentReportEditData = action.payload;
      }).addCase(viewIncidentReport.rejected, (state, action) => {
        state.ViewIncidentReportLoader = false;
        state.IncidentReportEditData = {};
        state.ViewIncidentReportData = {};
      })
      .addCase(deleteIncidentReport.pending, (state, action) => {

      }).addCase(deleteIncidentReport.fulfilled, (state, action) => {

      }).addCase(deleteIncidentReport.rejected, (state, action) => {

      })
      .addCase(saveParticipant.pending, (state, action) => {
        state.ParticipantButtonSpinner = true;
      }).addCase(saveParticipant.fulfilled, (state, action) => {
        state.ParticipantButtonSpinner = false;
      }).addCase(saveParticipant.rejected, (state, action) => {
        state.ParticipantButtonSpinner = false;
      })

      .addCase(saveParticipantAction.pending, (state, action) => {
        state.ParticipantActionButtonSpinner = true;
      }).addCase(saveParticipantAction.fulfilled, (state, action) => {
        state.ParticipantActionButtonSpinner = false;
      }).addCase(saveParticipantAction.rejected, (state, action) => {
        state.ParticipantActionButtonSpinner = false;
      })

      .addCase(saveIncidentAction.pending, (state, action) => {
        state.ActionButtonSpinner = true;
      }).addCase(saveIncidentAction.fulfilled, (state, action) => {
        state.ActionButtonSpinner = false;
      }).addCase(saveIncidentAction.rejected, (state, action) => {
        state.ActionButtonSpinner = false;
      })


      .addCase(saveOtherParticipant.pending, (state, action) => {
        state.otherParticipantButtonSpinner = true;
      }).addCase(saveOtherParticipant.fulfilled, (state, action) => {
        state.otherParticipantButtonSpinner = false;
      }).addCase(saveOtherParticipant.rejected, (state, action) => {
        state.otherParticipantButtonSpinner = false;
      })

      .addCase(getParticipantData.pending, (state, action) => {
        state.participantTableLoading = true;
      }).addCase(getParticipantData.fulfilled, (state, action) => {
        state.participantTableLoading = false;
        state.participantData = {
          ...state.participantData,
          ...action.payload
        }
      }).addCase(getParticipantData.rejected, (state, action) => {
        state.participantTableLoading = false;
      })


      .addCase(getIncidentDashboardData.pending, (state, action) => {
        state.incidentDashboardLoading = true;
      }).addCase(getIncidentDashboardData.fulfilled, (state, action) => {
        state.incidentDashboardLoading = false;
        state.countData = action.payload.countData;
        state.TimeFrame = action.payload.getTimeFrame;
        state.RequireAttention = action.payload.getRequireAttention;
        state.LocationWise = action.payload.getLocationWise;
        state.IncidentsForecast = action.payload.getIncidentsForecast;
        state.CategoryWise = action.payload.getCategoryWise;
        state.ActionWise = action.payload.getActionWise;
      }).addCase(getIncidentDashboardData.rejected, (state, action) => {
        state.incidentDashboardLoading = false;
      })

      .addCase(getIncidentActions.pending, (state, action) => {
        state.incidentActionLoading = true;
      }).addCase(getIncidentActions.fulfilled, (state, action) => {
        state.incidentActionLoading = false;
        state.incidentActions = action.payload;
      }).addCase(getIncidentActions.rejected, (state, action) => {
        state.incidentActionLoading = false;
      })

      .addCase(getLocationWise.pending, (state, action) => {
        state.incidentLocationWiseLoading = true;
      }).addCase(getLocationWise.fulfilled, (state, action) => {
        state.incidentLocationWiseLoading = false;
        state.LocationWise = action.payload.getLocationWise;
      }).addCase(getLocationWise.rejected, (state, action) => {
        state.incidentLocationWiseLoading = false;
      })


      .addCase(getCategoryWise.pending, (state, action) => {
        state.incidentTypeWiseLoading = true;
      }).addCase(getCategoryWise.fulfilled, (state, action) => {
        state.incidentTypeWiseLoading = false;
        state.CategoryWise = action.payload.getCategoryWise;
      }).addCase(getCategoryWise.rejected, (state, action) => {
        state.incidentTypeWiseLoading = false;
      })


      .addCase(getActionWise.pending, (state, action) => {
        state.incidentActionWiseLoading = true;
      }).addCase(getActionWise.fulfilled, (state, action) => {
        state.incidentActionWiseLoading = false;
        state.ActionWise = action.payload.getActionWise;
      }).addCase(getActionWise.rejected, (state, action) => {
        state.incidentActionWiseLoading = false;
      })


      .addCase(getIncidentsData.pending, (state, action) => {
        state.incidentDataLoading = true;
      }).addCase(getIncidentsData.fulfilled, (state, action) => {
        state.incidentDataLoading = false;
        state.IncidentsData = action.payload;
      }).addCase(getIncidentsData.rejected, (state, action) => {
        state.incidentDataLoading = false;
      })

      .addCase(deleteParticipant.pending, (state, action) => {
        state.participantTableLoading = true;
      }).addCase(deleteParticipant.fulfilled, (state, action) => {
        state.participantTableLoading = false;
      }).addCase(deleteParticipant.rejected, (state, action) => {
        state.participantTableLoading = false;
      });
  },
});

export const {
  setOtherParticipantData,
  setStudentSelectedRowKeys,
  setSelectedRowKeys,
  updateStudentSelectedRowKeys,
  updateSelectedRowKeys,
  setIncidentFormData,
  setIncidentParticipantFormData,
  setIncidentActionFormData,
  setIncidentFormStatus,
  setIncidentParticipantFormStatus,
  setIncidentParticipantStatus,
  setIncidentParticipantActionFormStatus,
  IncidentReportAddDrawerStatus,
  IncidentReportEditWithDrawerStatus,
  updateSortFilters,
  updateDashboardFilters,
  setStateClear,
  setCurrentPageStudentRecord,
  setCurrentPageUserRecord
} = manageIncidentReportSlice.actions;

export default manageIncidentReportSlice.reducer;
