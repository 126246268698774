import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ATTENDANCE_REPORT,
  // ATTENDANCE_REPORT_API_URL, 
  // GET_ALL_ATTENDANCE_REPORT_API_URL,
  GET_ATTENDANCE_CODES,
  GET_ATTENDANCE_BY_STATUS,
  GET_STUDENT_ATTENDANCE_REPORT,
  GET_ATTENDANCE_SUMMARY,
  GET_CLASS_STUDENTS,
  ATTENDANCE_DASHBOARD_REPORT
} from 'constants/attendance-reports/index';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import { at } from 'lodash';
import CommonService from 'services/CommonService/CommonService';
// import IntlMessage from "components/util-components/IntlMessage";  
// const setLocale = (localeKey, isLocaleOn = true) =>
//   isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  // AttendanceReportErrors       : {},
  permission: permission?.attendance_report ?? [],
  // AttendanceReportShowMessage  : null,
  // AttendanceReportResult       : [],
  AttendanceReportButtonSpinner: false,
  AttendanceReportLoading: false,
  // AttendanceReportAddDrawer    : false,
  // AttendanceReportEditData     : [],
  // AttendanceReportButtonAndModelLabel  : setLocale('attendancereport.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {
    organization_id: null
  },
  ViewAttendanceReportData: [],
  ViewAttendanceReportLoader: true,
  attendanceCodes: null,
  attendanceByStatus: null,
  studentAttReport: null,
  classStudents: null,
  attendanceDashboardReport: {},
  attendanceClassBar: {},
  attendanceWeeklyArea: {},
  attendanceStudentAttentions: []

};

// export const createAttendanceReport = createAsyncThunk("createAttendanceReport",async (data) => {
//     try {
//       return await CommonService.createAndUpdate(data, ATTENDANCE_REPORT_API_URL);      
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
//   }
// )

// export const getAttendanceReport = createAsyncThunk("getAttendanceReport",async (data) => {
//     try {
//       return await CommonService.getAllPost(data, GET_ALL_ATTENDANCE_REPORT_API_URL);      
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
//   }
// );

// export const viewAttendanceReport = createAsyncThunk(
//   "viewAttendanceReport",
//   async (id) => {
//     try {
//       const response = await CommonService.showOne(id, ATTENDANCE_REPORT_API_URL);
//       return response;
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
//   }
// );

// export const deleteAttendanceReport = createAsyncThunk(
//   "deleteAttendanceReport",
//   async (data) => {
//     try {
//       const response = await CommonService.deleteOne(data, ATTENDANCE_REPORT_API_URL);
//       return response;
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
//   }
// );
/**attendance reports */
export const getAttendanceDashboardReport = createAsyncThunk('getAttendanceDashboardReport', async (data) => {
  try {
    return CommonService.postData(data, ATTENDANCE_DASHBOARD_REPORT)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});
export const getAttendanceCodes = createAsyncThunk('getAttendanceCodes', async (data) => {
  try {
    return CommonService.getData(data, GET_ATTENDANCE_CODES)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});

export const getAttendanceByStatus = createAsyncThunk('getAttendanceByStatus', async (data) => {
  try {
    return CommonService.postData(data, GET_ATTENDANCE_BY_STATUS)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getSingleStudentAttReport = createAsyncThunk('getSingleStudentAttReport', async (data) => {
  try {
    return CommonService.postData(data, GET_STUDENT_ATTENDANCE_REPORT)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getAttendanceSummary = createAsyncThunk('getAttendanceSummary', async (data) => {
  try {
    return CommonService.postData(data, GET_ATTENDANCE_SUMMARY)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getClassStudents = createAsyncThunk('getClassStudents', async (data) => {
  try {
    return CommonService.postData(data, GET_CLASS_STUDENTS)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getMultipleClassStudents = createAsyncThunk('getMultipleClassStudents', async (data) => {
  try {
    return CommonService.postData(data, GET_CLASS_STUDENTS)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const manageAttendanceReportSlice = createSlice({
  name: ATTENDANCE_REPORT,
  initialState,
  reducers: {
    // AttendanceReportAddDrawerStatus: (state, action) => {
    //   state.AttendanceReportErrors      = {};
    //   state.AttendanceReportAddDrawer   = action.payload;
    //   state.AttendanceReportEditData    = [];
    //   state.AttendanceReportButtonAndModelLabel = setLocale('attendancereport.add');
    // },
    // AttendanceReportEditWithDrawerStatus: (state, action) => {
    //   state.AttendanceReportErrors = {};
    //   state.AttendanceReportAddDrawer = true;
    //   state.AttendanceReportEditData = action.payload;
    //   state.AttendanceReportButtonAndModelLabel = setLocale('attendancereport.edit');
    // },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    makeNullattendanceByStatus: (state, action) => {
      state.attendanceByStatus = null
    },
    makeStudentAttNull: (state, action) => {
      state.studentAttReport = null
    },
    changeReportView: (state, action) => {
      state.studentAttReport = null
      state.attendanceByStatus = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendanceDashboardReport.pending, (state, action) => {
        state.AttendanceReportButtonSpinner = true
        state.attendanceDashboardReport = {}
        state.attendanceClassBar = {}
        state.attendanceWeeklyArea = {}
        state.AttendanceReportLoading = true
        state.attendanceStudentAttentions = []
      }).addCase(getAttendanceDashboardReport.fulfilled, (state, action) => {
        state.attendanceDashboardReport = action.payload.data.attendanceCards
        state.attendanceClassBar = action.payload.data.attendanceClassBar
        state.attendanceWeeklyArea = action.payload.data.attendanceWeeklyArea
        state.attendanceStudentAttentions = action.payload.data.attendanceStudentAttention
        state.AttendanceReportLoading = false
        state.AttendanceReportButtonSpinner = false
      }).addCase(getAttendanceDashboardReport.rejected, (state, action) => {
        state.AttendanceReportLoading = false
        state.AttendanceReportButtonSpinner = false
      })
      .addCase(getClassStudents.pending, (state, action) => {
        state.classStudents = null
      }).addCase(getClassStudents.fulfilled, (state, action) => {
        state.classStudents = action.payload.data
      })
      .addCase(getMultipleClassStudents.pending, (state, action) => {
        state.classStudents = null
      }).addCase(getMultipleClassStudents.fulfilled, (state, action) => {
        state.classStudents = action.payload.data
      })
      .addCase(getAttendanceByStatus.fulfilled, (state, action) => {
        state.attendanceByStatus = action.payload.data;
      })
      .addCase(getSingleStudentAttReport.fulfilled, (state, action) => {
        state.studentAttReport = action.payload.data;
      })
      .addCase(getAttendanceSummary.fulfilled, (state, action) => {
        state.studentAttReport = action.payload.data;
      })
      .addCase(getAttendanceCodes.pending, (state, action) => {
      }).addCase(getAttendanceCodes.fulfilled, (state, action) => {
        state.attendanceCodes = action.payload.data
        state.AttendanceReportButtonSpinner = false;
        state.AttendanceReportErrors = {}
      }).addCase(getAttendanceCodes.rejected, (state, action) => {
        state.AttendanceReportShowMessage = true;
        state.AttendanceReportButtonSpinner = false;
        state.AttendanceReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })


    // .addCase(createAttendanceReport.pending, (state, action) => {
    //   state.AttendanceReportButtonSpinner = true;
    // }).addCase(createAttendanceReport.fulfilled, (state, action) => {
    //   state.AttendanceReportButtonSpinner = false;
    //   state.AttendanceReportErrors = {}
    // }).addCase(createAttendanceReport.rejected, (state, action) => {
    //   state.AttendanceReportShowMessage = true;
    //   state.AttendanceReportButtonSpinner = false;
    //   state.AttendanceReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
    // }).addCase(getAttendanceReport.pending, (state, action) => {
    //   state.AttendanceReportButtonSpinner = true;
    //   state.AttendanceReportLoading = true;
    // }).addCase(getAttendanceReport.fulfilled, (state, action) => {
    //   state.AttendanceReportButtonSpinner = false;
    //   state.AttendanceReportLoading = false;
    //   state.AttendanceReportResult = action.payload;
    //   state.tablePagination = {
    //       ...state.tablePagination,
    //       total: action.payload.total,
    //       current: action.payload.current_page,
    //       pageSize: action.payload.per_page
    //   };
    // }).addCase(getAttendanceReport.rejected, (state, action) => {
    //   state.AttendanceReportShowMessage = true; // Set the showMessage flag to display the errors
    //   state.AttendanceReportButtonSpinner = false;
    //   state.AttendanceReportLoading = false;
    //   state.AttendanceReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
    // })
    // .addCase(viewAttendanceReport.pending, (state, action) => {
    //   state.ViewAttendanceReportLoader = true;
    //   state.ViewAttendanceReportData = [];
    // }).addCase(viewAttendanceReport.fulfilled, (state, action) => {
    //   state.ViewAttendanceReportLoader = false;
    //   state.ViewAttendanceReportData = action.payload;
    // }).addCase(viewAttendanceReport.rejected, (state, action) => {
    //   state.ViewAttendanceReportLoader = false;
    //   state.ViewAttendanceReportData = [];
    // })
    // .addCase(deleteAttendanceReport.pending, (state, action) => {

    // }).addCase(deleteAttendanceReport.fulfilled, (state, action) => {

    // }).addCase(deleteAttendanceReport.rejected, (state, action) => {

    // });
  },
});

export const {
  // AttendanceReportAddDrawerStatus, 
  // AttendanceReportEditWithDrawerStatus, 
  updateSortFilters,
  makeNullattendanceByStatus,
  makeStudentAttNull,
  changeReportView
} = manageAttendanceReportSlice.actions;

export default manageAttendanceReportSlice.reducer;
