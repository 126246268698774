import fetch from 'auth/FetchInterceptor'

const Roles = {}

Roles.createRoles= function (data) {
	if (data && data.hasOwnProperty('id')) {
	  return fetch({
		url: `all-roles/${data.id}`,
		method: 'put',
		data
	  });
	} else {
	  return fetch({
		url: 'all-roles',
		method: 'post',
		data
	  });
	}
  };
//
Roles.getRoles= function () {
	
	return fetch({
		url: 'all-roles',
		method: 'get',
	})
}
//
Roles.showRoles= function (id) {
	
	return fetch({
		url: `all-roles/${id}`,
		method: 'get',
	})
}
//
Roles.deleteRoles= function (id) {
    return fetch({
        url: `all-roles/${id}`,
        method: 'delete',
    });
};


export default Roles;