import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  STUDENT_CHARGES,
  STUDENT_CHARGES_API_URL,
  GET_ALL_STUDENT_CHARGES_API_URL,
  STUDENT_CHARGES_OVERVIEW_API_URL,
  STUDENT_CHARGES_BREAKDOWN_API_URL,
  APPLY_TRANSPORT_FEE_API_URL,
  SINGLE_STUDENT_CHARGES_API_URL,
} from "constants/AppConstants";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(
  localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE)
);

export const initialState = {
  StudentChargesErrors: {},
  permission: permission?.student_charges ?? [],
  StudentChargesShowMessage: null,
  StudentChargesResult: [],
  StudentChargesButtonSpinner: false,
  StudentChargesTableLoading: true,
  StudentChargesAddDrawer: false,
  StudentChargesEditData: [],
  StudentChargesOverviewLoader: false,
  StudentChargesBreakdownLoader: false,
  StudentChargesOverviewData: [],
  StudentChargesBreakdownData: [],
  StudentChargesButtonAndModelLabel: setLocale(
    "charges_and_invoices.add_student_charges"
  ),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewStudentChargesData: [],
  ViewStudentChargesLoader: true,
};

export const createStudentCharges = createAsyncThunk(
  "createStudentCharges",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        STUDENT_CHARGES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentCharges = createAsyncThunk(
  "getStudentCharges",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_ALL_STUDENT_CHARGES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const addSingleStudentCharges = createAsyncThunk(
  "addSingleStudentCharges",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        SINGLE_STUDENT_CHARGES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const getStudentChargesOverview = createAsyncThunk(
  "getStudentChargesOverview",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        STUDENT_CHARGES_OVERVIEW_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentChargesBreakdown = createAsyncThunk(
  "getStudentChargesBreakdown",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        STUDENT_CHARGES_BREAKDOWN_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStudentCharges = createAsyncThunk(
  "viewStudentCharges",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, STUDENT_CHARGES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentCharges = createAsyncThunk(
  "deleteStudentCharges",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(
        data,
        STUDENT_CHARGES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const applyTransportation = createAsyncThunk(
  "applyTransportation",
  async (data) => {
    try {
      const response = await CommonService.getData(
        data,
        APPLY_TRANSPORT_FEE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageStudentChargesSlice = createSlice({
  name: STUDENT_CHARGES,
  initialState,
  reducers: {
    StudentChargesAddDrawerStatus: (state, action) => {
      state.StudentChargesErrors = {};
      state.StudentChargesAddDrawer = action.payload;
      state.StudentChargesEditData = [];
      state.StudentChargesButtonAndModelLabel = setLocale(
        "charges_and_invoices.add_student_charges"
      );
    },
    StudentChargesEditWithDrawerStatus: (state, action) => {
      state.StudentChargesErrors = {};
      state.StudentChargesAddDrawer = true;
      state.StudentChargesEditData = action.payload;
      state.StudentChargesButtonAndModelLabel = setLocale(
        "charges_and_invoices.edit_student_charges"
      );
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentCharges.pending, (state, action) => {
        state.StudentChargesButtonSpinner = true;
      })
      .addCase(createStudentCharges.fulfilled, (state, action) => {
        state.StudentChargesButtonSpinner = false;
        state.StudentChargesErrors = {};
      })
      .addCase(createStudentCharges.rejected, (state, action) => {
        state.StudentChargesShowMessage = true;
        state.StudentChargesButtonSpinner = false;
        state.StudentChargesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(addSingleStudentCharges.pending, (state, action) => {
        state.StudentChargesButtonSpinner = true;
      })
      .addCase(addSingleStudentCharges.fulfilled, (state, action) => {
        state.StudentChargesButtonSpinner = false;
        state.StudentChargesErrors = {};
      })
      .addCase(addSingleStudentCharges.rejected, (state, action) => {
        state.StudentChargesShowMessage = true;
        state.StudentChargesButtonSpinner = false;
        state.StudentChargesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getStudentCharges.pending, (state, action) => {
        state.StudentChargesButtonSpinner = true;
        state.StudentChargesTableLoading = true;
      })
      .addCase(getStudentCharges.fulfilled, (state, action) => {
        state.StudentChargesButtonSpinner = false;
        state.StudentChargesTableLoading = false;
        state.StudentChargesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload?.pagination?.total,
          current: action.payload?.pagination?.current_page,
          pageSize: action.payload?.pagination?.per_page,
        };
      })
      .addCase(getStudentCharges.rejected, (state, action) => {
        state.StudentChargesShowMessage = true; // Set the showMessage flag to display the errors
        state.StudentChargesButtonSpinner = false;
        state.StudentChargesTableLoading = false;
        state.StudentChargesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewStudentCharges.pending, (state, action) => {
        state.ViewStudentChargesLoader = true;
        state.ViewStudentChargesData = [];
      })
      .addCase(viewStudentCharges.fulfilled, (state, action) => {
        state.ViewStudentChargesLoader = false;
        state.ViewStudentChargesData = action.payload;
      })
      .addCase(viewStudentCharges.rejected, (state, action) => {
        state.ViewStudentChargesLoader = false;
        state.ViewStudentChargesData = [];
      })

      .addCase(applyTransportation.pending, (state, action) => {

      })
      .addCase(applyTransportation.fulfilled, (state, action) => {

      })
      .addCase(applyTransportation.rejected, (state, action) => {

      })


      .addCase(getStudentChargesOverview.pending, (state, action) => {
        state.StudentChargesOverviewLoader = true;
        state.StudentChargesOverviewData = [];
      })
      .addCase(getStudentChargesOverview.fulfilled, (state, action) => {
        state.StudentChargesOverviewLoader = false;
        state.StudentChargesOverviewData = action.payload;
      })
      .addCase(getStudentChargesOverview.rejected, (state, action) => {
        state.StudentChargesOverviewLoader = false;
        state.StudentChargesOverviewData = [];
      })

      .addCase(getStudentChargesBreakdown.pending, (state, action) => {
        state.StudentChargesBreakdownLoader = true;
        state.StudentChargesBreakdownData = [];
      })
      .addCase(getStudentChargesBreakdown.fulfilled, (state, action) => {
        state.StudentChargesBreakdownLoader = false;
        state.StudentChargesBreakdownData = action.payload;
      })
      .addCase(getStudentChargesBreakdown.rejected, (state, action) => {
        state.StudentChargesBreakdownLoader = false;
        state.StudentChargesBreakdownData = [];
      })
      .addCase(deleteStudentCharges.pending, (state, action) => { })
      .addCase(deleteStudentCharges.fulfilled, (state, action) => { })
      .addCase(deleteStudentCharges.rejected, (state, action) => { });
  },
});

export const {
  setColumnSearch,
  StudentChargesAddDrawerStatus,
  StudentChargesEditWithDrawerStatus,
  updateSortFilters,
} = manageStudentChargesSlice.actions;

export default manageStudentChargesSlice.reducer;
