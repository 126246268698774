import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ORGANIZATION, ORGANIZATION_API_URL, GET_LOGIN_USER_ORGANIZATIONS, GET_ALL_ORGANIZATION_API_URL, GET_ORGANIZATION_ROLE } from "constants/AppConstants"
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { FILE_BASE_URL } from "constants/ApiConstant";

const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  OrganizationErrors: {},
  permission: permission?.organization ?? [],
  OrganizationShowMessage: null,
  OrganizationResult: [],
  OrganizationButtonSpinner: false,
  OrganizationTableLoading: true,
  OrganizationAddDrawer: false,
  OrganizationEditData: [],
  OrganizationRoleResult: [],
  organizationLogo: null,

  OrganizationButtonAndModelLabel: setLocale('organization.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewOrganizationData: [],
  ViewOrganizationLoader: true,
  DrawerStatus: 0,
};

export const createOrganization = createAsyncThunk(
  "createOrganization",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrganization = createAsyncThunk(
  "getOrganization",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewOrganization = createAsyncThunk(
  "viewOrganization",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  "deleteOrganization",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getLoginUserOrganizations = createAsyncThunk(
  "getLoginUserOrganizations",
  async (data) => {
    try {
      return await CommonService.getData(data, GET_LOGIN_USER_ORGANIZATIONS)
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
    }
  });
export const getOrganizationRoles = createAsyncThunk(
  "getOrganizationRoles",
  async (data) => {
    try {
      const response = await CommonService.showOne(data, ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const getOrganizationRole = createAsyncThunk(
  "getOrganizationRole",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ORGANIZATION_ROLE);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageOrganizationSlice = createSlice({
  name: ORGANIZATION,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.OrganizationErrors = {};
    },
    OrganizationAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.OrganizationButtonSpinner = false;
      }
      // state.OrganizationErrors = {};
      state.OrganizationAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.OrganizationEditData = [];
      state.organizationLogo = null;
      state.OrganizationButtonAndModelLabel = setLocale('organization.add');
    },
    OrganizationEditWithDrawerStatus: (state, action) => {
      // state.OrganizationErrors = {};
      state.OrganizationAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.OrganizationEditData = {
        ...action.payload.data,
        address_1: action.payload.data.address.address_1,
        address_2: action.payload.data.address.address_2,
        city: action.payload.data.address.city,
        postal_code: action.payload.data.address.postal_code,
        cell_phone: action.payload.data.address.cell_phone,
        work_phone: action.payload.data.address.work_phone,
        home_phone: action.payload.data.address.home_phone,
        fax: action.payload.data.address.fax,
        addressId: action.payload.data.address.id,
      };
      state.organizationLogo = action.payload.data?.org_logo ? FILE_BASE_URL + action.payload.data?.org_logo : null



      state.OrganizationButtonAndModelLabel = setLocale('organization.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setOrganizationLogo: (state, action) => {
      state.organizationLogo = action.payload
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrganization.pending, (state, action) => {
        state.DrawerStatus = 0
        state.OrganizationButtonSpinner = true;
      }).addCase(createOrganization.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.OrganizationButtonSpinner = false;
      }).addCase(createOrganization.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.OrganizationShowMessage = true;
        state.OrganizationButtonSpinner = false;
        state.OrganizationErrors = JSON.parse(action.error.message);
      }).addCase(getOrganization.pending, (state, action) => {
        state.OrganizationButtonSpinner = true;
        state.OrganizationTableLoading = true;
      }).addCase(getOrganization.fulfilled, (state, action) => {
        state.OrganizationButtonSpinner = false;
        state.OrganizationTableLoading = false;
        state.OrganizationResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getOrganization.rejected, (state, action) => {
        state.OrganizationButtonSpinner = false;
        state.OrganizationTableLoading = false;
      })
      .addCase(viewOrganization.pending, (state, action) => {
        state.ViewOrganizationLoader = true;
        state.ViewOrganizationData = [];
      }).addCase(viewOrganization.fulfilled, (state, action) => {
        state.ViewOrganizationLoader = false;
        state.ViewOrganizationData = action.payload;
      }).addCase(viewOrganization.rejected, (state, action) => {
        state.ViewOrganizationLoader = false;
        state.ViewOrganizationData = [];
      })
      .addCase(deleteOrganization.pending, (state, action) => {

      }).addCase(deleteOrganization.fulfilled, (state, action) => {

      }).addCase(deleteOrganization.rejected, (state, action) => {

      })
      .addCase(getLoginUserOrganizations.pending, (state, action) => {
        state.OrganizationButtonSpinner = true
      })
      .addCase(getLoginUserOrganizations.fulfilled, (state, action) => {
        state.loginUserOrganizations = action.payload.data
        state.OrganizationButtonSpinner = false
        state.OrganizationErrors = {}
      })
      .addCase(getLoginUserOrganizations.rejected, (state, action) => {
        state.OrganizationErrors = JSON.parse(action.error.message)
        state.OrganizationShowMessage = true
        state.OrganizationButtonSpinner = false
      })
      .addCase(getOrganizationRoles.pending, (state, action) => {
        state.OrganizationButtonSpinner = true;
      })
      .addCase(getOrganizationRoles.fulfilled, (state, action) => {
        state.OrganizationButtonSpinner = false;
        state.OrganizationRoleResult = action.payload;
        state.OrganizationErrors = {};
      })
      .addCase(getOrganizationRoles.rejected, (state, action) => {
        state.OrganizationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrganizationShowMessage = true; // Set the showMessage flag to display the errors
        state.OrganizationButtonSpinner = false;
      })

      .addCase(getOrganizationRole.pending, (state, action) => {
        state.OrganizationButtonSpinner = true;
      })
      .addCase(getOrganizationRole.fulfilled, (state, action) => {
        state.OrganizationButtonSpinner = false;
        state.OrganizationRoleResult = action.payload;
        state.OrganizationErrors = {};
      })
      .addCase(getOrganizationRole.rejected, (state, action) => {
        state.OrganizationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrganizationShowMessage = true; // Set the showMessage flag to display the errors
        state.OrganizationButtonSpinner = false;
      });
  },
});


export const {onCloseError, setColumnSearch, OrganizationAddDrawerStatus, OrganizationEditWithDrawerStatus, updateSortFilters, setOrganizationLogo } = manageOrganizationSlice.actions;

export default manageOrganizationSlice.reducer;

