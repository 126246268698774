import fetch from "auth/FetchInterceptor";
const PermissionService = {};
PermissionService.createPermission = function (data) {
  if (data && data.hasOwnProperty("id")) {
    return fetch({
      url: `permission-management/${data.id}`,
      method: "put",
      data,
    });
  } else {
    return fetch({
      url: "permission-management",
      method: "post",
      data: data,
    });
  }
};

PermissionService.getPermission = function () {
  return fetch({
    url: "permission-management",
    method: "get",
  });
};

PermissionService.deletePermission = function (id) {
  return fetch({
    url: `permission-management/${id}`,
    method: "delete",
  });
};

export default PermissionService;
