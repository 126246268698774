export const AUTH_TOKEN = "auth_token";
export const DASHBOARD_NAV_TREE = "dashBoardNavTree";
export const USER_ROLE = "userRole";
export const USER_INFORMATION = "userInformation";
export const ALLOWED_URL = "allowedUrls";
export const PROTECTED_ROUTE = "protectedRoutes";
export const USER_ALL_INFORMATION_ORGANIZATION_WISE = "userAllInformationOrganizationWise";
export const EMAIL = 'email';
export const DEFAULT_ROUTE = 'default';
export const IMPERSONATE = 'impersonate';
export const VERSION = 'appVersion';
export const DEFAULT_YEAR = 'defaultYear';
