import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {CONVERSATION_CHANNEL, CONVERSATION_CHANNEL_API_URL, GET_ALL_CONVERSATION_CHANNEL_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ConversationChannelErrors       : {},
  permission: permission?.conversation_channel ?? [],
  ConversationChannelShowMessage  : null,
  ConversationChannelResult       : [],
  ConversationChannelButtonSpinner : false,
  ConversationChannelTableLoading : true,
  ConversationChannelAddDrawer    : false,
  ConversationChannelEditData     : [],
  ConversationChannelButtonAndModelLabel  : setLocale('conversation_channel.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewConversationChannelData: [],
    ViewConversationChannelLoader: true,
    DrawerStatus: 0,
};

export const createConversationChannel = createAsyncThunk(
    "createConversationChannel",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CONVERSATION_CHANNEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getConversationChannel = createAsyncThunk(
    "getConversationChannel",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CONVERSATION_CHANNEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewConversationChannel = createAsyncThunk(
  "viewConversationChannel",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CONVERSATION_CHANNEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteConversationChannel = createAsyncThunk(
  "deleteConversationChannel",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CONVERSATION_CHANNEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageConversationChannelSlice = createSlice({
  name: CONVERSATION_CHANNEL,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ConversationChannelErrors = {};
    },
    ConversationChannelAddDrawerStatus: (state, action) => {
      // state.ConversationChannelErrors      = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ConversationChannelAddDrawer   = action.payload.status;
      state.ConversationChannelEditData    = [];
      state.ConversationChannelButtonAndModelLabel = setLocale('conversation_channel.add');
    },
    ConversationChannelEditWithDrawerStatus: (state, action) => {
      // state.ConversationChannelErrors = {};
      state.ConversationChannelAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ConversationChannelEditData = action.payload.data;
      state.ConversationChannelButtonAndModelLabel = setLocale('conversation_channel.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createConversationChannel.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ConversationChannelButtonSpinner = true;
      }).addCase(createConversationChannel.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ConversationChannelButtonSpinner = false;
        state.ConversationChannelErrors = {}
      }).addCase(createConversationChannel.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ConversationChannelShowMessage = true;
        state.ConversationChannelButtonSpinner = false;
        state.ConversationChannelErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getConversationChannel.pending, (state, action) => {
        state.ConversationChannelButtonSpinner = true;
        state.ConversationChannelTableLoading = true;
      }).addCase(getConversationChannel.fulfilled, (state, action) => {
        state.ConversationChannelButtonSpinner = false;
        state.ConversationChannelTableLoading = false;
        state.ConversationChannelResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getConversationChannel.rejected, (state, action) => {
        state.ConversationChannelShowMessage = true; // Set the showMessage flag to display the errors
        state.ConversationChannelButtonSpinner = false;
        state.ConversationChannelTableLoading = false;
        state.ConversationChannelErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewConversationChannel.pending, (state, action) => {
        state.ViewConversationChannelLoader = true;
        state.ViewConversationChannelData = [];
      }).addCase(viewConversationChannel.fulfilled, (state, action) => {
        state.ViewConversationChannelLoader = false;
        state.ViewConversationChannelData = action.payload;
      }).addCase(viewConversationChannel.rejected, (state, action) => {
        state.ViewConversationChannelLoader = false;
        state.ViewConversationChannelData = [];
      })
      .addCase(deleteConversationChannel.pending, (state, action) => {

      }).addCase(deleteConversationChannel.fulfilled, (state, action) => {

      }).addCase(deleteConversationChannel.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, ConversationChannelAddDrawerStatus, ConversationChannelEditWithDrawerStatus, updateSortFilters } = manageConversationChannelSlice.actions;

export default manageConversationChannelSlice.reducer;
