import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GRADE_BOOK_CATEGORY, GRADE_BOOK_CATEGORY_API_URL, GET_ALL_GRADE_BOOK_CATEGORY_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  GradeBookCategoryErrors: {},
  permission: permission?.grade_book_category ?? [],
  GradeBookCategoryShowMessage: null,
  GradeBookCategoryResult: [],
  GradeBookCategoryButtonSpinner: false,
  GradeBookCategoryTableLoading: true,
  GradeBookCategoryAddDrawer: false,
  GradeBookCategoryEditData: [],
  GradeBookCategoryButtonAndModelLabel: setLocale('grade_book_category.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewGradeBookCategoryData: [],
  ViewGradeBookCategoryLoader: true,

  selectedCategory:null,
  addNewGradeCategoryModal:false,
  saveBtnLoading:false,
  DrawerStatus: 0,
};

export const createGradeBookCategory = createAsyncThunk(
  "createGradeBookCategory",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, GRADE_BOOK_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGradeBookCategory = createAsyncThunk(
  "getGradeBookCategory",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_GRADE_BOOK_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewGradeBookCategory = createAsyncThunk(
  "viewGradeBookCategory",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, GRADE_BOOK_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteGradeBookCategory = createAsyncThunk(
  "deleteGradeBookCategory",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, GRADE_BOOK_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageGradeBookCategorySlice = createSlice({
  name: GRADE_BOOK_CATEGORY,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.GradeBookCategoryErrors = {};
    },
    GradeBookCategoryAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.GradeBookCategoryButtonSpinner = false;
      }
      // state.GradeBookCategoryErrors = {};
      state.RelationshipAddDrawer = action.payload.status;
      state.GradeBookCategoryAddDrawer = action.payload.status;
      state.GradeBookCategoryEditData = [];
      state.GradeBookCategoryButtonAndModelLabel = setLocale('grade_book_category.add');
    },
    GradeBookCategoryEditWithDrawerStatus: (state, action) => {
      // state.GradeBookCategoryErrors = {};
      state.GradeBookCategoryAddDrawer = true;
      state.RelationshipAddDrawer = action.payload.status;
      state.GradeBookCategoryEditData = action.payload.data;
      state.GradeBookCategoryButtonAndModelLabel = setLocale('grade_book_category.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setAddNewGradeCategoryModal(state, action){
      state.addNewGradeCategoryModal = action.payload
    },
    setSaveBtnLoading(state, action){
        state.saveBtnLoading = action.payload
    },
    setSelectedCategory(state, action){
        state.selectedCategory = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGradeBookCategory.pending, (state, action) => {
        state.DrawerStatus = 0
        state.GradeBookCategoryButtonSpinner = true;
      }).addCase(createGradeBookCategory.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.GradeBookCategoryButtonSpinner = false;
        state.GradeBookCategoryErrors = {}
      }).addCase(createGradeBookCategory.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.GradeBookCategoryShowMessage = true;
        state.GradeBookCategoryButtonSpinner = false;
        state.GradeBookCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getGradeBookCategory.pending, (state, action) => {
        state.GradeBookCategoryButtonSpinner = true;
        state.GradeBookCategoryTableLoading = true;
      }).addCase(getGradeBookCategory.fulfilled, (state, action) => {
        state.GradeBookCategoryButtonSpinner = false;
        state.GradeBookCategoryTableLoading = false;
        state.GradeBookCategoryResult = action.payload.data;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getGradeBookCategory.rejected, (state, action) => {
        state.GradeBookCategoryShowMessage = true; // Set the showMessage flag to display the errors
        state.GradeBookCategoryButtonSpinner = false;
        state.GradeBookCategoryTableLoading = false;
        state.GradeBookCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewGradeBookCategory.pending, (state, action) => {
        state.ViewGradeBookCategoryLoader = true;
        state.ViewGradeBookCategoryData = [];
      }).addCase(viewGradeBookCategory.fulfilled, (state, action) => {
        state.ViewGradeBookCategoryLoader = false;
        state.ViewGradeBookCategoryData = action.payload;
      }).addCase(viewGradeBookCategory.rejected, (state, action) => {
        state.ViewGradeBookCategoryLoader = false;
        state.ViewGradeBookCategoryData = [];
      })
      .addCase(deleteGradeBookCategory.pending, (state, action) => {

      }).addCase(deleteGradeBookCategory.fulfilled, (state, action) => {

      }).addCase(deleteGradeBookCategory.rejected, (state, action) => {

      });
  },
});

export const { 
  onCloseError, 
  setSelectedCategory,
  setAddNewGradeCategoryModal,
  setSaveBtnLoading,

  setColumnSearch, 
  GradeBookCategoryAddDrawerStatus, 
  GradeBookCategoryEditWithDrawerStatus, 
  updateSortFilters
} = manageGradeBookCategorySlice.actions;

export default manageGradeBookCategorySlice.reducer;
