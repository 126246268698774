import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {PROVINCE, PROVINCE_API_URL, GET_ALL_PROVINCE_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ProvinceErrors       : {},
  permission: permission?.province ?? [],
  ProvinceShowMessage  : null,
  ProvinceResult       : [],
  ProvinceButtonSpinner : false,
  ProvinceTableLoading : true,
  ProvinceAddDrawer    : false,
  ProvinceEditData     : [],
  ProvinceButtonAndModelLabel  : setLocale('province.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewProvinceData: [],
    ViewProvinceLoader: true,
    DrawerStatus: 0,
};

export const createProvince = createAsyncThunk(
    "createProvince",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, PROVINCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getProvince = createAsyncThunk(
    "getProvince",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_PROVINCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewProvince = createAsyncThunk(
  "viewProvince",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, PROVINCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteProvince = createAsyncThunk(
  "deleteProvince",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PROVINCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageProvinceSlice = createSlice({
  name: PROVINCE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ProvinceErrors = {};
    },
    ProvinceAddDrawerStatus: (state, action) => {
      // state.ProvinceErrors      = {};
      state.ProvinceAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ProvinceEditData    = [];
      state.ProvinceButtonAndModelLabel = setLocale('province.add');
    },
    ProvinceEditWithDrawerStatus: (state, action) => {
      // state.ProvinceErrors = {};
      state.ProvinceAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ProvinceEditData = action.payload.data;
      state.ProvinceButtonAndModelLabel = setLocale('province.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProvince.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ProvinceButtonSpinner = true;
      }).addCase(createProvince.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ProvinceButtonSpinner = false;
        state.ProvinceErrors = {}
      }).addCase(createProvince.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ProvinceShowMessage = true;
        state.ProvinceButtonSpinner = false;
        state.ProvinceErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getProvince.pending, (state, action) => {
        state.ProvinceButtonSpinner = true;
        state.ProvinceTableLoading = true;
      }).addCase(getProvince.fulfilled, (state, action) => {
        state.ProvinceButtonSpinner = false;
        state.ProvinceTableLoading = false;
        state.ProvinceResult = action.payload.data;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getProvince.rejected, (state, action) => {
        state.ProvinceShowMessage = true; // Set the showMessage flag to display the errors
        state.ProvinceButtonSpinner = false;
        state.ProvinceTableLoading = false;
        state.ProvinceErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewProvince.pending, (state, action) => {
        state.ViewProvinceLoader = true;
        state.ViewProvinceData = [];
      }).addCase(viewProvince.fulfilled, (state, action) => {
        state.ViewProvinceLoader = false;
        state.ViewProvinceData = action.payload;
      }).addCase(viewProvince.rejected, (state, action) => {
        state.ViewProvinceLoader = false;
        state.ViewProvinceData = [];
      })
      .addCase(deleteProvince.pending, (state, action) => {

      }).addCase(deleteProvince.fulfilled, (state, action) => {

      }).addCase(deleteProvince.rejected, (state, action) => {

      });
  },
});

export const {onCloseError,setColumnSearch, ProvinceAddDrawerStatus, ProvinceEditWithDrawerStatus, updateSortFilters } = manageProvinceSlice.actions;

export default manageProvinceSlice.reducer;
