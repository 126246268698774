import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {SALUTATION, SALUTATION_API_URL, GET_ALL_SALUTATION_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  SalutationErrors       : {},
  permission: permission?.salutation ?? [],
  SalutationShowMessage  : null,
  SalutationResult       : [],
  SalutationButtonSpinner : false,
  SalutationTableLoading : true,
  SalutationAddDrawer    : false,
  SalutationEditData     : [],
  SalutationButtonAndModelLabel  : setLocale('salutation.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewSalutationData: [],
    ViewSalutationLoader: true,
    DrawerStatus: 0,
};

export const createSalutation = createAsyncThunk(
    "createSalutation",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, SALUTATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getSalutation = createAsyncThunk(
    "getSalutation",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_SALUTATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewSalutation = createAsyncThunk(
  "viewSalutation",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, SALUTATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteSalutation = createAsyncThunk(
  "deleteSalutation",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, SALUTATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageSalutationSlice = createSlice({
  name: SALUTATION,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.SalutationErrors = {};
    },
    SalutationAddDrawerStatus: (state, action) => {
      // state.SalutationErrors      = {};
      state.SalutationAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.SalutationEditData    = [];
      state.SalutationButtonAndModelLabel = setLocale('salutation.add');
    },
    SalutationEditWithDrawerStatus: (state, action) => {
      // state.SalutationErrors = {};
      state.SalutationAddDrawer = true;
      state.SalutationEditData = action.payload.data;
      state.DrawerStatus = action.payload.errorStatus;
      state.SalutationButtonAndModelLabel = setLocale('salutation.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSalutation.pending, (state, action) => {
        state.DrawerStatus = 0
        state.SalutationButtonSpinner = true;
      }).addCase(createSalutation.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.SalutationButtonSpinner = false;
        state.SalutationErrors = {}
      }).addCase(createSalutation.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.SalutationShowMessage = true;
        state.SalutationButtonSpinner = false;
        state.SalutationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getSalutation.pending, (state, action) => {
        state.SalutationButtonSpinner = true;
        state.SalutationTableLoading = true;
      }).addCase(getSalutation.fulfilled, (state, action) => {
        state.SalutationButtonSpinner = false;
        state.SalutationTableLoading = false;
        state.SalutationResult = action.payload.data;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getSalutation.rejected, (state, action) => {
        state.SalutationShowMessage = true; // Set the showMessage flag to display the errors
        state.SalutationButtonSpinner = false;
        state.SalutationTableLoading = false;
        state.SalutationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewSalutation.pending, (state, action) => {
        state.ViewSalutationLoader = true;
        state.ViewSalutationData = [];
      }).addCase(viewSalutation.fulfilled, (state, action) => {
        state.ViewSalutationLoader = false;
        state.ViewSalutationData = action.payload;
      }).addCase(viewSalutation.rejected, (state, action) => {
        state.ViewSalutationLoader = false;
        state.ViewSalutationData = [];
      })
      .addCase(deleteSalutation.pending, (state, action) => {

      }).addCase(deleteSalutation.fulfilled, (state, action) => {

      }).addCase(deleteSalutation.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, SalutationAddDrawerStatus, SalutationEditWithDrawerStatus, updateSortFilters } = manageSalutationSlice.actions;

export default manageSalutationSlice.reducer;
