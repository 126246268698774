import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { INCIDENT_ACTION_CODE, INCIDENT_ACTION_CODE_API_URL, GET_ALL_INCIDENT_ACTION_CODE_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  IncidentActioncodeErrors: {},
  permission: permission?.incident_action_code ?? [],
  IncidentActioncodeShowMessage: null,
  IncidentActioncodeResult: [],
  IncidentActioncodeButtonSpinner: false,
  IncidentActioncodeTableLoading: true,
  IncidentActioncodeAddDrawer: false,
  IncidentActioncodeEditData: [],
  IncidentActioncodeButtonAndModelLabel: setLocale('incidentactioncode.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewIncidentActioncodeData: [],
  ViewIncidentActioncodeLoader: true,
};

export const createIncidentActioncode = createAsyncThunk(
  "createIncidentActioncode",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, INCIDENT_ACTION_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getIncidentActioncode = createAsyncThunk(
  "getIncidentActioncode",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_INCIDENT_ACTION_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewIncidentActioncode = createAsyncThunk(
  "viewIncidentActioncode",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, INCIDENT_ACTION_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteIncidentActioncode = createAsyncThunk(
  "deleteIncidentActioncode",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, INCIDENT_ACTION_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageIncidentActioncodeSlice = createSlice({
  name: INCIDENT_ACTION_CODE,
  initialState,
  reducers: {
    IncidentActioncodeAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.IncidentActioncodeButtonSpinner = false;
      }
      state.IncidentActioncodeErrors = {};
      state.IncidentActioncodeAddDrawer = action.payload;
      state.IncidentActioncodeEditData = [];
      state.IncidentActioncodeButtonAndModelLabel = setLocale('incidentactioncode.add');
    },
    IncidentActioncodeEditWithDrawerStatus: (state, action) => {
      state.IncidentActioncodeErrors = {};
      state.IncidentActioncodeAddDrawer = true;
      state.IncidentActioncodeEditData = action.payload;
      state.IncidentActioncodeButtonAndModelLabel = setLocale('incidentactioncode.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createIncidentActioncode.pending, (state, action) => {
        state.IncidentActioncodeButtonSpinner = true;
      }).addCase(createIncidentActioncode.fulfilled, (state, action) => {
        state.IncidentActioncodeButtonSpinner = false;
        state.IncidentActioncodeErrors = {}
      }).addCase(createIncidentActioncode.rejected, (state, action) => {
        state.IncidentActioncodeShowMessage = true;
        state.IncidentActioncodeButtonSpinner = false;
        state.IncidentActioncodeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getIncidentActioncode.pending, (state, action) => {
        state.IncidentActioncodeButtonSpinner = true;
        state.IncidentActioncodeTableLoading = true;
      }).addCase(getIncidentActioncode.fulfilled, (state, action) => {
        state.IncidentActioncodeButtonSpinner = false;
        state.IncidentActioncodeTableLoading = false;
        state.IncidentActioncodeResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getIncidentActioncode.rejected, (state, action) => {
        state.IncidentActioncodeShowMessage = true; // Set the showMessage flag to display the errors
        state.IncidentActioncodeButtonSpinner = false;
        state.IncidentActioncodeTableLoading = false;
        state.IncidentActioncodeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewIncidentActioncode.pending, (state, action) => {
        state.ViewIncidentActioncodeLoader = true;
        state.ViewIncidentActioncodeData = [];
      }).addCase(viewIncidentActioncode.fulfilled, (state, action) => {
        state.ViewIncidentActioncodeLoader = false;
        state.ViewIncidentActioncodeData = action.payload;
      }).addCase(viewIncidentActioncode.rejected, (state, action) => {
        state.ViewIncidentActioncodeLoader = false;
        state.ViewIncidentActioncodeData = [];
      })
      .addCase(deleteIncidentActioncode.pending, (state, action) => {

      }).addCase(deleteIncidentActioncode.fulfilled, (state, action) => {

      }).addCase(deleteIncidentActioncode.rejected, (state, action) => {

      });
  },
});

export const { setColumnSearch, IncidentActioncodeAddDrawerStatus, IncidentActioncodeEditWithDrawerStatus, updateSortFilters } = manageIncidentActioncodeSlice.actions;

export default manageIncidentActioncodeSlice.reducer;
