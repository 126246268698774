import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  USER,
  USER_API_URL,
  UPDATE_USER_STATUS_API_URL,
  AUTO_GENERATE_PASSWORD_API_URL,
  UPDATE_PASSWORD_BY_ADMIN_API_URL,
  ADD_USER_NEW_ORGANIZATION,
  GET_USER_ORGANIZATION_ROLES,
  DETACH_USER_ORGANIZATION_ROLES,
  UPDATE_CURRENT_USER_PASSWORD,
  UPDATE_USER_PROFILE_PICTURE,
  REMOVE_USER_PROFILE_PICTURE,
  USER_LOGIN_HISTORY_API_URL,
  UPDATE_USERS_PASSWORD,
  TAG_MACHINE_TO_USER_API_URL,
  USER_SIGNATURE
} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  UserErrors: {},
  permission: permission?.user ?? [],
  UserShowMessage: null,
  UserResult: [],
  selectedUserRec: [],
  UserLoginHistoryResult: [],
  UserButtonSpinner: false,
  UserTableLoading: true,
  ManageHistoryTableLoading: true,
  UserAddDrawer: false,
  UserLoginHistoryDrawer: false,
  UserEditData: null,
  UserButtonAndModelLabel: setLocale('users.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  historyTablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewUserData: [],
  ViewUserLoader: true,
  userUpdatePasswordModel: false,
  adminUpdatePasswordModel: false,
  tagMachineModel: false,
  loading: false,
  tagLoading: false,
  userOrganizationModel: false,
  userOrganizationRoles: null,
  userOrganizationRolesTable: false
};

export const createUser = createAsyncThunk("createUser", async (data) => {
  try {
    return data && data.hasOwnProperty('id') ?
      await CommonService.putData(data, USER_API_URL) :
      await CommonService.postData(data, USER_API_URL)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
}
)
export const getAllUsers = createAsyncThunk("getAllUsers", async (data) => {
  try {
    return await CommonService.getData(data, USER_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
)
export const viewUser = createAsyncThunk("viewUser", async (id) => {
  try {
    return await CommonService.showOne(id, USER_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
}
)
export const setUserProfilePicture = createAsyncThunk('setUserProfilePicture', async (data) => {
  try {
    return await CommonService.postData(data, UPDATE_USER_PROFILE_PICTURE)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const removeProfilePicture = createAsyncThunk('removeProfilePicture', async (data) => {
  try {
    return await CommonService.postData(data, REMOVE_USER_PROFILE_PICTURE)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const setUserSignature = createAsyncThunk('setUserSignature', async (data) => {
  try {
    return await CommonService.postData(data, USER_SIGNATURE)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, USER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

/**update User Status */
export const updateUserStatus = createAsyncThunk("updateUserStatus", async (data) => {
  try {
    const response = await CommonService.getAllPost(data, UPDATE_USER_STATUS_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
/**
 * auto generated password by admin
 */
export const autoGenerateUserPassword = createAsyncThunk('autoGenerateUserPassword', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, AUTO_GENERATE_PASSWORD_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
/**
 * update password by admin
 */
export const updateUserPasswordByAdmin = createAsyncThunk('updateUserPasswordByAdmin', async (data) => {
  try {
    return await CommonService.getAllPost(data, UPDATE_PASSWORD_BY_ADMIN_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const tagMachineToUser = createAsyncThunk('tagMachineToUser', async (data) => {
  try {
    return await CommonService.getAllPost(data, TAG_MACHINE_TO_USER_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const updateCurrentUserPassword = createAsyncThunk('updateCurrentUserPassword', async (data) => {
  try {
    return await CommonService.postData(data, UPDATE_CURRENT_USER_PASSWORD);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**
 * addNewUserOrganization
 */
export const addNewUserOrganization = createAsyncThunk('addNewUserOrganization', async (data) => {
  try {
    return await CommonService.postData(data, ADD_USER_NEW_ORGANIZATION);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
/** 
 * get User Roles and organizations
 */
export const getUserOrganizationRoles = createAsyncThunk('getUserOrganizationRoles', async (data) => {
  try {
    return await CommonService.getData(data, GET_USER_ORGANIZATION_ROLES);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
/**
 * 
 */
export const detachUserOrganizationRoles = createAsyncThunk('detachUserOrganizationRoles', async (data) => {
  try {
    return await CommonService.postData(data, DETACH_USER_ORGANIZATION_ROLES);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
export const updateUsersPassword = createAsyncThunk('updateUsersPassword', async (data) => {
  try {
    return await CommonService.postData(data, UPDATE_USERS_PASSWORD);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getUserLoginHistory = createAsyncThunk("getUserLoginHistory", async (data) => {
  try {
    return await CommonService.getData(data, USER_LOGIN_HISTORY_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
)
export const manageUserSlice = createSlice({
  name: USER,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.UserErrors = {};
    },
    UserAddDrawerStatus: (state, action) => {
      // state.UserErrors      = {};
      state.UserAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.UserEditData = [];
      state.UserButtonAndModelLabel = setLocale('users.add');
    },
    UserEditWithDrawerStatus: (state, action) => {
      // state.UserErrors = {};
      state.UserAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.UserEditData = action.payload.data;
      state.UserButtonAndModelLabel = setLocale('users.edit');
    },
    LoginHistoryDrawerStatus: (state, action) => {
      state.UserErrors = {};
      state.UserLoginHistoryDrawer = action.payload;
      state.UserEditData = action.payload;
      state.UserButtonAndModelLabel = setLocale('users.login_history');
    },
    setUserUpdatePasswordModel: (state, action) => {
      state.userUpdatePasswordModel = action.payload
    },
    setAdminUpdatePasswordModel: (state, action) => {
      state.adminUpdatePasswordModel = action.payload
    },
    setTagMachineModel: (state, action) => {
      state.tagMachineModel = action.payload.status
      state.selectedUserRec = action.payload.data
    },
    setUserOrganizationModel: (state, action) => {
      state.userOrganizationModel = action.payload
    },
    setSelectedUserData: (state, action) => {
      state.UserEditData = action.payload
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    updateHistorySortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserOrganizationRoles.pending, (state, action) => {
        state.userOrganizationRolesTable = true
        state.UserShowMessage = false
      }).addCase(getUserOrganizationRoles.fulfilled, (state, action) => {
        state.userOrganizationRoles = action.payload.data
        state.userOrganizationRolesTable = false
      }).addCase(getUserOrganizationRoles.rejected, (state, action) => {
        state.UserShowMessage = true;
        state.userOrganizationRolesTable = false;
      })
      .addCase(createUser.pending, (state, action) => {
        state.UserButtonSpinner = true
        state.DrawerStatus = 0
        state.UserShowMessage = false
      }).addCase(createUser.fulfilled, (state, action) => {
        state.UserButtonSpinner = false;
        state.DrawerStatus = 0
        state.UserErrors = {}
      }).addCase(createUser.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.UserShowMessage = true;
        state.UserButtonSpinner = false;
        state.UserErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(addNewUserOrganization.pending, (state, action) => {
        state.UserButtonSpinner = true;
        state.UserErrors = {}
        state.UserShowMessage = false
      }).addCase(addNewUserOrganization.fulfilled, (state, action) => {
        state.UserButtonSpinner = false;
        state.UserErrors = {}
      }).addCase(addNewUserOrganization.rejected, (state, action) => {
        state.UserShowMessage = true;
        state.UserButtonSpinner = false;
        state.UserErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getAllUsers.pending, (state, action) => {
        state.UserButtonSpinner = true;
        state.UserTableLoading = true;
        state.UserResult = [];
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.UserButtonSpinner = false;
        state.UserTableLoading = false;
        state.UserResult = action.payload.data
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.UserShowMessage = true; // Set the showMessage flag to display the errors
        state.UserButtonSpinner = false;
        state.UserTableLoading = false;
        state.UserErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewUser.pending, (state, action) => {
        state.ViewUserLoader = true;
        state.ViewUserData = [];
      }).addCase(viewUser.fulfilled, (state, action) => {
        state.ViewUserLoader = false;
        state.ViewUserData = action.payload;
      }).addCase(viewUser.rejected, (state, action) => {
        state.ViewUserLoader = false;
        state.ViewUserData = [];
      })
      .addCase(deleteUser.pending, (state, action) => {

      }).addCase(deleteUser.fulfilled, (state, action) => {

      }).addCase(deleteUser.rejected, (state, action) => {

      })
      .addCase(updateUserPasswordByAdmin.pending, (state, action) => {
        state.loading = true
      }).addCase(updateUserPasswordByAdmin.fulfilled, (state, action) => {
        state.loading = false
        state.adminUpdatePasswordModel = false
      }).addCase(updateUserPasswordByAdmin.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(tagMachineToUser.pending, (state, action) => {
        state.tagLoading = true
      }).addCase(tagMachineToUser.fulfilled, (state, action) => {
        state.tagLoading = false
        state.tagMachineModel = false
      }).addCase(tagMachineToUser.rejected, (state, action) => {
        state.tagLoading = false
      })

      .addCase(updateCurrentUserPassword.pending, (state, action) => {
        state.loading = true
      }).addCase(updateCurrentUserPassword.fulfilled, (state, action) => {
        state.loading = false
        state.userUpdatePasswordModel = false
      }).addCase(updateCurrentUserPassword.rejected, (state, action) => {
        state.loading = false
        state.UserShowMessage = true
        state.UserButtonSpinner = false
        state.UserTableLoading = false
        // state.UserErrors = JSON.parse(action.error.message)
      })
      .addCase(setUserProfilePicture.pending, (state, action) => {

      }).addCase(setUserProfilePicture.fulfilled, (state, action) => {
        state.ViewUserData = action.payload.data
      }).addCase(setUserProfilePicture.rejected, (state, action) => {
        state.loading = false
        state.UserShowMessage = true
        state.UserButtonSpinner = false
        state.UserTableLoading = false
      })

      .addCase(removeProfilePicture.pending, (state, action) => {

      }).addCase(removeProfilePicture.fulfilled, (state, action) => {
        state.ViewUserData = action.payload.data
      }).addCase(removeProfilePicture.rejected, (state, action) => {
        state.loading = false
        state.UserShowMessage = true
        state.UserButtonSpinner = false
        state.UserTableLoading = false
      })
      .addCase(setUserSignature.pending, (state, action) => {
        state.UserButtonSpinner = true;
      }).addCase(setUserSignature.fulfilled, (state, action) => {        
        state.ViewUserData = action.payload.data
        state.UserButtonSpinner = false;
        state.UserErrors = {};
        state.UserShowMessage = false;
      }).addCase(setUserSignature.rejected, (state, action) => {
        state.UserButtonSpinner = false;
        state.UserShowMessage = true;
        state.UserErrors = JSON.parse(action.error.message);
      })
      .addCase(getUserLoginHistory.pending, (state, action) => {
        state.UserButtonSpinner = true;
        state.ManageHistoryTableLoading = true;
      })
      .addCase(getUserLoginHistory.fulfilled, (state, action) => {
        state.UserButtonSpinner = false;
        state.ManageHistoryTableLoading = false;
        state.UserLoginHistoryResult = action.payload.data
        state.historyTablePagination = {
          ...state.historyTablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getUserLoginHistory.rejected, (state, action) => {
        state.UserShowMessage = true; // Set the showMessage flag to display the errors
        state.UserButtonSpinner = false;
        state.ManageHistoryTableLoading = false;
        state.UserErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
  },
});

export const {
  onCloseError,
  UserAddDrawerStatus,
  UserEditWithDrawerStatus,
  LoginHistoryDrawerStatus,
  updateSortFilters,
  updateHistorySortFilters,
  setColumnSearch,
  setUserUpdatePasswordModel,
  setAdminUpdatePasswordModel,
  setTagMachineModel,
  setSelectedUserData
} = manageUserSlice.actions;

export default manageUserSlice.reducer;
