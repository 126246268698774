import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_MODULE_GROUP, NAME_OF_MODULE_GROUP_SLICE } from 'constants/AppConstants';
import ModuleService from 'services/Modules/ModuleService';

export const initialState = {
  ModuleGroupErrors       : {},
  ModuleGroupShowMessage  : null,
  ModuleGroupResult       : [],
  ModuleGroupButtonSpiner : false,
  AddModuleGroupDrawer    : false,
  EditModuleGroupData     : [],
  ModalGroupLabel         : 'Create Group'
};

export const createModuleGroup = createAsyncThunk(
  CREATE_MODULE_GROUP,
  async (data) => {
    try {
      const response = await ModuleService.createModule(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageModuleGroupSlice = createSlice({
  name: NAME_OF_MODULE_GROUP_SLICE,
  initialState,
  reducers: {
    AddModuleGroupDrawerStatus: (state, action) => {
      state.AddModuleGroupDrawer = action.payload;
      state.EditModuleGroupData = [];
      state.ModalGroupLabel = 'Create Group';
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(createModuleGroup.pending, (state, action) => {
        state.ModuleGroupButtonSpiner = true;
      })
      .addCase(createModuleGroup.fulfilled, (state, action) => {
        state.ModuleGroupButtonSpiner = false;
        state.ModuleGroupErrors = {}
      })
      .addCase(createModuleGroup.rejected, (state, action) => {
        state.ModuleGroupErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ModuleGroupShowMessage = true; // Set the showMessage flag to display the errors
        state.ModuleGroupButtonSpiner = false;
      });
  },
});

export const { AddModuleGroupDrawerStatus } = manageModuleGroupSlice.actions;

export default manageModuleGroupSlice.reducer;
