import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STUDENT_LEDGER, STUDENT_LEDGER_API_URL, GET_ALL_STUDENT_LEDGER_API_URL, GET_GRADE_LEVEL_STUDENT_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE, USER_INFORMATION } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const userInformation = JSON.parse(localStorage.getItem(USER_INFORMATION));
const selectedOrganization = userInformation?.organization_id;
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentLedgerErrors: {},
  permission: permission?.student_ledger ?? [],
  StudentLedgerShowMessage: null,
  StudentLedgerResult: [],
  gradeStudentButtonSpinner: false,
  GradeStudentResult: [],
  StudentLedgerButtonSpinner: false,
  StudentLedgerTableLoading: false,
  StudentLedgerAddDrawer: false,
  StudentLedgerEditData: [],
  StudentLedgerButtonAndModelLabel: setLocale('studentledger.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {
    organization_id: selectedOrganization,
  },
  ViewStudentLedgerData: [],
  ViewStudentLedgerLoader: true,
  DrawerStatus: 0,
};

export const createStudentLedger = createAsyncThunk(
  "createStudentLedger",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STUDENT_LEDGER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentLedger = createAsyncThunk(
  "getStudentLedger",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_STUDENT_LEDGER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const getGradeLevelStudent = createAsyncThunk(
  "getGradeLevelStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_GRADE_LEVEL_STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const viewStudentLedger = createAsyncThunk(
  "viewStudentLedger",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, STUDENT_LEDGER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentLedger = createAsyncThunk(
  "deleteStudentLedger",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, STUDENT_LEDGER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageStudentLedgerSlice = createSlice({
  name: STUDENT_LEDGER,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.StudentLedgerErrors = {};
    },
    StudentLedgerAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.StudentLedgerButtonSpinner = false;
      }
      state.StudentLedgerAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.StudentLedgerEditData = [];
      state.StudentLedgerButtonAndModelLabel = setLocale('studentledger.add');
    },
    StudentLedgerEditWithDrawerStatus: (state, action) => {
      state.StudentLedgerAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.StudentLedgerEditData = action.payload.data;
      state.StudentLedgerButtonAndModelLabel = setLocale('studentledger.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    resetData: (state, action) => {
      state.StudentLedgerResult = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentLedger.pending, (state, action) => {
        state.DrawerStatus = 0
        state.StudentLedgerButtonSpinner = true;
      }).addCase(createStudentLedger.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.StudentLedgerButtonSpinner = false;
        state.StudentLedgerErrors = {}
      }).addCase(createStudentLedger.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.StudentLedgerShowMessage = true;
        state.StudentLedgerButtonSpinner = false;
        state.StudentLedgerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getStudentLedger.pending, (state, action) => {
        state.StudentLedgerButtonSpinner = true;
        state.StudentLedgerTableLoading = true;
      }).addCase(getStudentLedger.fulfilled, (state, action) => {
        state.StudentLedgerButtonSpinner = false;
        state.StudentLedgerTableLoading = false;
        state.StudentLedgerResult = action.payload;
      }).addCase(getStudentLedger.rejected, (state, action) => {
        state.StudentLedgerShowMessage = true; // Set the showMessage flag to display the errors
        state.StudentLedgerButtonSpinner = false;
        state.StudentLedgerTableLoading = false;
        state.StudentLedgerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getGradeLevelStudent.pending, (state, action) => {
        state.gradeStudentButtonSpinner = true;
      })
      .addCase(getGradeLevelStudent.fulfilled, (state, action) => {
        state.GradeStudentResult = action.payload;
        state.gradeStudentButtonSpinner = false;
      })
      .addCase(getGradeLevelStudent.rejected, (state, action) => {
        state.gradeStudentButtonSpinner = false;
      })
      .addCase(viewStudentLedger.pending, (state, action) => {
        state.ViewStudentLedgerLoader = true;
        state.ViewStudentLedgerData = [];
      }).addCase(viewStudentLedger.fulfilled, (state, action) => {
        state.ViewStudentLedgerLoader = false;
        state.ViewStudentLedgerData = action.payload;
      }).addCase(viewStudentLedger.rejected, (state, action) => {
        state.ViewStudentLedgerLoader = false;
        state.ViewStudentLedgerData = [];
      })
      .addCase(deleteStudentLedger.pending, (state, action) => {
        state.StudentLedgerTableLoading = true;
      }).addCase(deleteStudentLedger.fulfilled, (state, action) => {
        state.StudentLedgerTableLoading = false;
      }).addCase(deleteStudentLedger.rejected, (state, action) => {
        state.StudentLedgerTableLoading = false;
      });
  },
});

export const { resetData, onCloseError, setColumnSearch, StudentLedgerAddDrawerStatus, StudentLedgerEditWithDrawerStatus, updateSortFilters } = manageStudentLedgerSlice.actions;

export default manageStudentLedgerSlice.reducer;
