import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DOCUMENT_STUDENT, DOCUMENT_STUDENT_API_URL, GET_ALL_DOCUMENT_STUDENT_API_URL, STUDENT_DOCUMENTS_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  DocumentStudentErrors: {},
  permission: permission?.document_student ?? [],
  DocumentStudentShowMessage: null,
  DocumentStudentResult: [],
  DocumentStudentButtonSpinner: false,
  DocumentStudentTableLoading: true,
  DocumentStudentAddDrawer: false,
  DocumentStudentEditData: [],
  DocumentStudentButtonAndModelLabel: setLocale('Add Student Documents'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewDocumentStudentData: [],
  ViewDocumentStudentLoader: true,
  getStudentDocumentsData: [],
  getStudentDocumentsLoader: true,
  DrawerStatus: 0,
};

export const createDocumentStudent = createAsyncThunk(
  "createDocumentStudent",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, DOCUMENT_STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getDocumentStudent = createAsyncThunk(
  "getDocumentStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_DOCUMENT_STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentDocuments = createAsyncThunk(
  "getStudentDocuments",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, STUDENT_DOCUMENTS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
)

export const viewDocumentStudent = createAsyncThunk(
  "viewDocumentStudent",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, DOCUMENT_STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteDocumentStudent = createAsyncThunk(
  "deleteDocumentStudent",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, DOCUMENT_STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageDocumentStudentSlice = createSlice({
  name: DOCUMENT_STUDENT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RelationshipErrors = {};
    },
    DocumentStudentAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.DocumentStudentButtonSpinner = false;
      }
      state.DocumentStudentAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.DocumentStudentEditData = [];
      state.DocumentStudentButtonAndModelLabel = setLocale('Add Student Documents');
    },
    DocumentStudentEditWithDrawerStatus: (state, action) => {
      state.DocumentStudentAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.DocumentStudentEditData = action.payload.data;
      state.DocumentStudentButtonAndModelLabel = setLocale('Edit Student Documents');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDocumentStudent.pending, (state, action) => {
        state.DrawerStatus = 0
        state.DocumentStudentButtonSpinner = true;
      }).addCase(createDocumentStudent.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.DocumentStudentButtonSpinner = false;
        state.DocumentStudentErrors = {}
      }).addCase(createDocumentStudent.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.DocumentStudentShowMessage = true;
        state.DocumentStudentButtonSpinner = false;
        state.DocumentStudentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getDocumentStudent.pending, (state, action) => {
        state.DocumentStudentButtonSpinner = true;
        state.DocumentStudentTableLoading = true;
      }).addCase(getDocumentStudent.fulfilled, (state, action) => {
        state.DocumentStudentButtonSpinner = false;
        state.DocumentStudentTableLoading = false;
        state.DocumentStudentResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getDocumentStudent.rejected, (state, action) => {
        state.DocumentStudentShowMessage = true; // Set the showMessage flag to display the errors
        state.DocumentStudentButtonSpinner = false;
        state.DocumentStudentTableLoading = false;
        state.DocumentStudentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewDocumentStudent.pending, (state, action) => {
        state.ViewDocumentStudentLoader = true;
        state.ViewDocumentStudentData = [];
      }).addCase(viewDocumentStudent.fulfilled, (state, action) => {
        state.ViewDocumentStudentLoader = false;
        state.ViewDocumentStudentData = action.payload;
      }).addCase(viewDocumentStudent.rejected, (state, action) => {
        state.ViewDocumentStudentLoader = false;
        state.ViewDocumentStudentData = [];
      })
      .addCase(getStudentDocuments.pending, (state, action) => {
        state.getStudentDocumentsLoader = true;
        state.getStudentDocumentsData = [];
      }).addCase(getStudentDocuments.fulfilled, (state, action) => {
        state.getStudentDocumentsLoader = false;
        state.getStudentDocumentsData = action.payload;
      }).addCase(getStudentDocuments.rejected, (state, action) => {
        state.getStudentDocumentsLoader = false;
        state.getStudentDocumentsData = [];
      })
      .addCase(deleteDocumentStudent.pending, (state, action) => {
        state.DocumentStudentTableLoading = true;
      }).addCase(deleteDocumentStudent.fulfilled, (state, action) => {
        state.DocumentStudentTableLoading = false;
      }).addCase(deleteDocumentStudent.rejected, (state, action) => {
        state.DocumentStudentTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, DocumentStudentAddDrawerStatus, DocumentStudentEditWithDrawerStatus, updateSortFilters } = manageDocumentStudentSlice.actions;

export default manageDocumentStudentSlice.reducer;
