import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PAYMENT, PAYMENT_API_URL, GET_ALL_PAYMENT_API_URL, GET_ALL_STUDENT_PENDING_INVOICES_API_URL, IMPORT_EFT_FILE_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  PaymentErrors: {},
  permission: permission?.payment ?? [],
  PaymentShowMessage: null,
  PaymentResult: [],
  StudentPendingInvoice: [],
  PaymentButtonSpinner: false,
  loadChildComponent: false,
  PaymentTableLoading: true,
  PaymentAddDrawer: false,
  ImportEFTFileLoading: false,
  PaymentEditData: [],
  PaymentButtonAndModelLabel: setLocale('payment.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewPaymentData: [],
  ViewPaymentLoader: true,
  DrawerStatus: 0,
  fileList: []
};

export const createPayment = createAsyncThunk(
  "createPayment",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, PAYMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getPayment = createAsyncThunk(
  "getPayment",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_PAYMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getPendingInvoice = createAsyncThunk(
  "getPendingInvoice",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_STUDENT_PENDING_INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewPayment = createAsyncThunk(
  "viewPayment",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, PAYMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deletePayment = createAsyncThunk(
  "deletePayment",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PAYMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const importEFTFile = createAsyncThunk(
  "importEFTFile",
  async (data) => {
    try {
      const response = await CommonService.postData(data, IMPORT_EFT_FILE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const managePaymentSlice = createSlice({
  name: PAYMENT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.PaymentErrors = {};
    },
    PaymentAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.PaymentButtonSpinner = false;
      }
      // state.PaymentErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.PaymentAddDrawer = action.payload.status;
      state.PaymentEditData = [];
      state.PaymentButtonAndModelLabel = setLocale('payment.add');
    },
    PaymentEditWithDrawerStatus: (state, action) => {
      // state.PaymentErrors = {};
      state.PaymentAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.PaymentEditData = action.payload.data;
      state.PaymentButtonAndModelLabel = setLocale('payment.edit');
    },
    setLoadChildComponent: (state, action) => {
      state.loadChildComponent = action.payload;
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setFileList: (state, action) => {
      state.fileList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayment.pending, (state, action) => {
        state.DrawerStatus = 0
        state.PaymentButtonSpinner = true;
      }).addCase(createPayment.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.PaymentButtonSpinner = false;
        state.PaymentErrors = {}
      }).addCase(createPayment.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.PaymentShowMessage = true;
        state.PaymentButtonSpinner = false;
        state.PaymentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getPayment.pending, (state, action) => {
        state.PaymentButtonSpinner = true;
        state.PaymentTableLoading = true;
      }).addCase(getPayment.fulfilled, (state, action) => {
        state.PaymentButtonSpinner = false;
        state.PaymentTableLoading = false;
        state.PaymentResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getPayment.rejected, (state, action) => {
        state.PaymentShowMessage = true; // Set the showMessage flag to display the errors
        state.PaymentButtonSpinner = false;
        state.PaymentTableLoading = false;
        state.PaymentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getPendingInvoice.pending, (state, action) => {
        state.PendingInvoicesLoading = true;
      }).addCase(getPendingInvoice.fulfilled, (state, action) => {
        state.PendingInvoicesLoading = false;
      }).addCase(getPendingInvoice.rejected, (state, action) => {
        state.PendingInvoicesLoading = false;
      })

      .addCase(importEFTFile.pending, (state, action) => {
        state.ImportEFTFileLoading = true;
      }).addCase(importEFTFile.fulfilled, (state, action) => {
        state.ImportEFTFileLoading = false;
        state.StudentPendingInvoice = action.payload;
      }).addCase(importEFTFile.rejected, (state, action) => {
        state.ImportEFTFileLoading = false;
        console.log(action)
        state.PaymentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })


      .addCase(viewPayment.pending, (state, action) => {
        state.ViewPaymentLoader = true;
        state.ViewPaymentData = [];
      }).addCase(viewPayment.fulfilled, (state, action) => {
        state.ViewPaymentLoader = false;
        state.ViewPaymentData = action.payload;
      }).addCase(viewPayment.rejected, (state, action) => {
        state.ViewPaymentLoader = false;
        state.ViewPaymentData = [];
      })
      .addCase(deletePayment.pending, (state, action) => {

      }).addCase(deletePayment.fulfilled, (state, action) => {

      }).addCase(deletePayment.rejected, (state, action) => {

      });
  },
});

export const { setFileList, onCloseError, setColumnSearch, setLoadChildComponent, PaymentAddDrawerStatus, PaymentEditWithDrawerStatus, updateSortFilters } = managePaymentSlice.actions;

export default managePaymentSlice.reducer;
