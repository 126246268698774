import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  RECEIVABLES_REPORT, 
  RECEIVABLES_REPORT_API_URL, 
  GET_ALL_RECEIVABLES_REPORT_API_URL,
  STUDENT_RECEIVEABLE_REPORT
} from 'constants/AppConstants';
import { 
  USER_ALL_INFORMATION_ORGANIZATION_WISE 
} from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ReceivablesReportErrors       : {},
  permission: permission?.receivables_report ?? [],
  ReceivablesReportShowMessage  : null,
  ReceivablesReportResult       : [],
  ReceivablesReportButtonSpinner : false,
  ReceivablesReportTableLoading : true,
  ReceivablesReportAddDrawer    : false,
  ReceivablesReportEditData     : [],
  ReceivablesReportButtonAndModelLabel  : setLocale('receivablesreport.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    DrawerStatus: 0,
    
    ViewReceivablesReportData: null,
    ViewReceivablesReportLoader: false,
    
};

export const getStudentReceiveableReport = createAsyncThunk("getStudentReceiveableReport", async (data) => {
  try {
   return await CommonService.postData(data, STUDENT_RECEIVEABLE_REPORT);    
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});
export const createReceivablesReport = createAsyncThunk(
    "createReceivablesReport",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getReceivablesReport = createAsyncThunk(
    "getReceivablesReport",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewReceivablesReport = createAsyncThunk(
  "viewReceivablesReport",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteReceivablesReport = createAsyncThunk(
  "deleteReceivablesReport",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageReceivablesReportSlice = createSlice({
  name: RECEIVABLES_REPORT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ReceivablesReportErrors = {};
    },
    ReceivablesReportAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.ReceivablesReportButtonSpinner = false;
      }
      state.ReceivablesReportAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReceivablesReportEditData    = [];
      state.ReceivablesReportButtonAndModelLabel = setLocale('receivablesreport.add');
    },
    ReceivablesReportEditWithDrawerStatus: (state, action) => {
      state.ReceivablesReportAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReceivablesReportEditData = action.payload.data;
      state.ReceivablesReportButtonAndModelLabel = setLocale('receivablesreport.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentReceiveableReport.pending, (state, action) => {
        state.ViewReceivablesReportLoader = true;
        state.ViewReceivablesReportData = null;
      }).addCase(getStudentReceiveableReport.fulfilled, (state, action) => {        
        state.ViewReceivablesReportData = action.payload;
        state.ViewReceivablesReportLoader = false;
      }).addCase(getStudentReceiveableReport.rejected, (state, action) => {
        state.ViewReceivablesReportLoader = false;
      })
      .addCase(createReceivablesReport.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ReceivablesReportButtonSpinner = true;
      }).addCase(createReceivablesReport.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportErrors = {}
      }).addCase(createReceivablesReport.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ReceivablesReportShowMessage = true;
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getReceivablesReport.pending, (state, action) => {
        state.ReceivablesReportButtonSpinner = true;
        state.ReceivablesReportTableLoading = true;
      }).addCase(getReceivablesReport.fulfilled, (state, action) => {
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportTableLoading = false;
        state.ReceivablesReportResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getReceivablesReport.rejected, (state, action) => {
        state.ReceivablesReportShowMessage = true; // Set the showMessage flag to display the errors
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportTableLoading = false;
        state.ReceivablesReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewReceivablesReport.pending, (state, action) => {
        state.ViewReceivablesReportLoader = true;
        state.ViewReceivablesReportData = [];
      }).addCase(viewReceivablesReport.fulfilled, (state, action) => {
        state.ViewReceivablesReportLoader = false;
        state.ViewReceivablesReportData = action.payload;
      }).addCase(viewReceivablesReport.rejected, (state, action) => {
        state.ViewReceivablesReportLoader = false;
        state.ViewReceivablesReportData = [];
      })
      .addCase(deleteReceivablesReport.pending, (state, action) => {
        state.ReceivablesReportTableLoading = true;
      }).addCase(deleteReceivablesReport.fulfilled, (state, action) => {
        state.ReceivablesReportTableLoading = false;
      }).addCase(deleteReceivablesReport.rejected, (state, action) => {
        state.ReceivablesReportTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, ReceivablesReportAddDrawerStatus, ReceivablesReportEditWithDrawerStatus, updateSortFilters } = manageReceivablesReportSlice.actions;

export default manageReceivablesReportSlice.reducer;
