import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RELATIONSHIP, RELATIONSHIP_API_URL, GET_ALL_RELATIONSHIP_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  RelationshipErrors: {},
  permission: permission?.relationship ?? [],
  RelationshipShowMessage: null,
  RelationshipResult: [],
  RelationshipButtonSpinner: false,
  RelationshipTableLoading: true,
  RelationshipAddDrawer: false,
  RelationshipEditData: [],
  RelationshipButtonAndModelLabel: setLocale('relationship.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewRelationshipData: [],
  ViewRelationshipLoader: true,
  DrawerStatus: 0,

};

export const createRelationship = createAsyncThunk(
  "createRelationship",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, RELATIONSHIP_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getRelationship = createAsyncThunk(
  "getRelationship",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_RELATIONSHIP_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewRelationship = createAsyncThunk(
  "viewRelationship",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, RELATIONSHIP_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteRelationship = createAsyncThunk(
  "deleteRelationship",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, RELATIONSHIP_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageRelationshipSlice = createSlice({
  name: RELATIONSHIP,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RelationshipErrors = {};
    },
    RelationshipAddDrawerStatus: (state, action) => {
      state.RelationshipAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.RelationshipEditData = [];
      state.RelationshipButtonAndModelLabel = setLocale('relationship.add');
    },
    RelationshipEditWithDrawerStatus: (state, action) => {
      state.RelationshipAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.RelationshipEditData = action.payload.data;
      state.RelationshipButtonAndModelLabel = setLocale('relationship.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRelationship.pending, (state, action) => {
        state.DrawerStatus = 0
        state.RelationshipButtonSpinner = true;
      }).addCase(createRelationship.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.RelationshipButtonSpinner = false;
        state.RelationshipErrors = {}
      }).addCase(createRelationship.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.RelationshipShowMessage = true;
        state.RelationshipButtonSpinner = false;
        state.RelationshipErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getRelationship.pending, (state, action) => {
        state.RelationshipButtonSpinner = true;
        state.RelationshipTableLoading = true;
      }).addCase(getRelationship.fulfilled, (state, action) => {
        state.RelationshipButtonSpinner = false;
        state.RelationshipTableLoading = false;
        state.RelationshipResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getRelationship.rejected, (state, action) => {
        state.RelationshipShowMessage = true; // Set the showMessage flag to display the errors
        state.RelationshipButtonSpinner = false;
        state.RelationshipTableLoading = false;
      })
      .addCase(viewRelationship.pending, (state, action) => {
        state.ViewRelationshipLoader = true;
        state.ViewRelationshipData = [];
      }).addCase(viewRelationship.fulfilled, (state, action) => {
        state.ViewRelationshipLoader = false;
        state.ViewRelationshipData = action.payload;
      }).addCase(viewRelationship.rejected, (state, action) => {
        state.ViewRelationshipLoader = false;
        state.ViewRelationshipData = [];
      })
      .addCase(deleteRelationship.pending, (state, action) => {

      }).addCase(deleteRelationship.fulfilled, (state, action) => {

      }).addCase(deleteRelationship.rejected, (state, action) => {

      });
  },
});

export const { onCloseError, setColumnSearch, RelationshipAddDrawerStatus, RelationshipEditWithDrawerStatus, updateSortFilters } = manageRelationshipSlice.actions;

export default manageRelationshipSlice.reducer;
