import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import {
	ATTENDANCE_SLICE,
  SAVE_BULK_ATT_MODIFICATION,
	GET_ATT_FOR_MODIFICATION,
	GET_CLASS_STUDENTS_FOR_ATT,
	MARK_ATTENDANCE_FULL_CLASS,
	MODIFY_SINGLE_STUDENT_ATT,
	MARK_BULK_STUDENT_ATT,
	GET_ATTENDANCE_CODES,
	GET_ATTENDANCE_LATEST_POLICY
} from 'constants/attendance/index'
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));


export const initialState = {
	AttendanceLoading:false,
	AttendanceErrors: {},
	AttendanceShowMessage: false,
	AttendanceButtonSpinner: false,
	classStudentsForAttendance: [],
	attendanceCodes: [],
	isAttMarked: false,
	attendanceForModification: [],
	permission: permission?.manage_class ?? [],
	attendanceLatestPolicy: null
}

export const getAttendanceLatestPolicy = createAsyncThunk('getAttendanceLatestPolicy', async (data) => {
  try {
		return CommonService.getData(data,GET_ATTENDANCE_LATEST_POLICY)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})
export const getAttendanceCodes = createAsyncThunk('getAttendanceCodes', async (data) => {
	try {
		return CommonService.getData(data,GET_ATTENDANCE_CODES)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})
export const getClassStudentsForAtt = createAsyncThunk('getClassStudentsForAtt', async (data) => {
	try {
		return CommonService.getData(data,GET_CLASS_STUDENTS_FOR_ATT)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})
export const markAttFullClass = createAsyncThunk('markAttFullClass', async (data) => {
	try {
		return CommonService.postData(data,MARK_ATTENDANCE_FULL_CLASS)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})
export const modifySingleStudentAtt = createAsyncThunk('modifySingleStudentAtt', async (data) => {
	try {
		return CommonService.postData(data,MODIFY_SINGLE_STUDENT_ATT)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})
/**mark bulk attendance for student */
export const markBulkStudentAtt = createAsyncThunk('markBulkStudentAtt', async (data) => {
	try {
		return CommonService.postData(data,MARK_BULK_STUDENT_ATT)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})
export const modifyBulkAtt = createAsyncThunk('modifyBulkAtt', async (data) => {
  try {
		return CommonService.postData(data,SAVE_BULK_ATT_MODIFICATION)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})

export const getAttendanceForModification = createAsyncThunk('getAttendanceForModification', async (data) => {
	try {
		return CommonService.postData(data,GET_ATT_FOR_MODIFICATION)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
})

export const attendanceSlice = createSlice({
	name: ATTENDANCE_SLICE,
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder
			
			.addCase(getAttendanceLatestPolicy.pending, (state, action) => {
			}).addCase(getAttendanceLatestPolicy.fulfilled, (state, action) => {
				state.attendanceLatestPolicy = action.payload.data
				state.AttendanceButtonSpinner = false
				state.AttendanceErrors = {}
			}).addCase(getAttendanceLatestPolicy.rejected, (state, action) => {
				state.AttendanceShowMessage = true
				state.AttendanceButtonSpinner = false
				state.AttendanceErrors = JSON.parse(action.error.message)
			})
			.addCase(modifyBulkAtt.pending, (state, action) => {
				state.AttendanceButtonSpinner = true
			}).addCase(modifyBulkAtt.fulfilled, (state, action) => {				
				state.AttendanceButtonSpinner = false
				state.AttendanceErrors = {}
			}).addCase(modifyBulkAtt.rejected, (state, action) => {
				state.AttendanceShowMessage = true
				state.AttendanceButtonSpinner = false
				state.AttendanceErrors = JSON.parse(action.error.message)
			})
			.addCase(getAttendanceCodes.pending, (state, action) => {
			}).addCase(getAttendanceCodes.fulfilled, (state, action) => {
				state.attendanceCodes = action.payload.data
			}).addCase(getAttendanceCodes.rejected, (state, action) => {
				state.AttendanceShowMessage = true
				state.AttendanceButtonSpinner = false
				state.AttendanceErrors = JSON.parse(action.error.message)
			})
			.addCase(getClassStudentsForAtt.pending, (state, action) => {
				state.AttendanceLoading = true
			}).addCase(getClassStudentsForAtt.fulfilled, (state, action) => {
				state.classStudentsForAttendance = action.payload.data.activeStudents
				state.isAttMarked = action.payload.data.isAttMarked
				state.AttendanceLoading = false
			}).addCase(getClassStudentsForAtt.rejected, (state, action) => {
        state.AttendanceShowMessage = true
        state.AttendanceButtonSpinner = false
				state.AttendanceLoading = false
        state.AttendanceErrors = JSON.parse(action.error.message)
      })
			.addCase(markAttFullClass.pending, (state, action) => {	
				state.AttendanceLoading = true			
			}).addCase(markAttFullClass.fulfilled, (state, action) => {				
			}).addCase(markAttFullClass.rejected, (state, action) => {
        state.AttendanceShowMessage = true
        state.AttendanceButtonSpinner = false
				state.AttendanceLoading = false
        state.AttendanceErrors = JSON.parse(action.error.message)
      })
			.addCase(modifySingleStudentAtt.pending, (state, action) => {	
				state.AttendanceButtonSpinner = true			
			}).addCase(modifySingleStudentAtt.fulfilled, (state, action) => {	
				state.AttendanceButtonSpinner = false			
			}).addCase(modifySingleStudentAtt.rejected, (state, action) => {
        state.AttendanceShowMessage = true
        state.AttendanceButtonSpinner = false
				state.AttendanceLoading = false
        state.AttendanceErrors = JSON.parse(action.error.message)
      })
			.addCase(markBulkStudentAtt.pending, (state, action) => {	
				state.AttendanceButtonSpinner = true			
			}).addCase(markBulkStudentAtt.fulfilled, (state, action) => {	
				state.AttendanceButtonSpinner = false			
			}).addCase(markBulkStudentAtt.rejected, (state, action) => {
        state.AttendanceShowMessage = true
        state.AttendanceButtonSpinner = false
				state.AttendanceLoading = false
        state.AttendanceErrors = JSON.parse(action.error.message)
      })
			.addCase(getAttendanceForModification.pending, (state, action) => {	
				state.AttendanceButtonSpinner = true		
				state.AttendanceLoading = true	
			}).addCase(getAttendanceForModification.fulfilled, (state, action) => {	
				state.AttendanceButtonSpinner = false		
				state.AttendanceLoading = false	
				state.attendanceForModification = action.payload.data
			}).addCase(getAttendanceForModification.rejected, (state, action) => {
        state.AttendanceShowMessage = true
        state.AttendanceButtonSpinner = false
				state.AttendanceLoading = false
        state.AttendanceErrors = JSON.parse(action.error.message)
      })
			

	},
})

export const {

} = attendanceSlice.actions

export default attendanceSlice.reducer