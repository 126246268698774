import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_ROLE, DELETE_ROLE, GET_ROLE, NAME_OF_ROLE_SLICE, GET_ORGANIZATION_ROLES } from 'constants/AppConstants';
import Roles from 'services/Roles/Roles';
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

export const initialState = {
  RolesErrors: {},
  RolesShowMessage: null,
  RolesResult: [],
  RolesButtonSpinner: false,
  RolesAddDrawer: false,
  RolesEditData: {},
  RolesButtonAndModelLabel: setLocale('roles.add'),
  organizationRoles: []
};

export const createRoles = createAsyncThunk(
  CREATE_ROLE,
  async (data) => {
    try {
      const response = await Roles.createRoles(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getRoles = createAsyncThunk(
  GET_ROLE,
  async (data) => {
    try {
      const response = await Roles.getRoles(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteRoles = createAsyncThunk(
  DELETE_ROLE,
  async (data) => {
    try {
      const response = await Roles.deleteRoles(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrganizationRoles = createAsyncThunk('getOrganizationRoles', async (data) => {
  try {
    return await CommonService.getData(data, GET_ORGANIZATION_ROLES)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const manageRolesSlice = createSlice({
  name: NAME_OF_ROLE_SLICE,
  initialState,
  reducers: {
    RolesAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.RolesButtonSpinner = false;
      }
      state.RolesErrors = {};
      state.RolesAddDrawer = action.payload;
      state.RolesEditData = {};
      state.RolesButtonAndModelLabel = setLocale('roles.add');
    },
    RolesEditWithDrawerStatus: (state, action) => {
      state.RolesErrors = {};
      state.RolesAddDrawer = true;
      state.RolesEditData = action.payload;
      state.RolesButtonAndModelLabel = setLocale('roles.edit');
    },
    pushTeacherGuardianRoles: (state, action) => {
     if(action.payload?.includes(3)) {
       state.organizationRoles.push({ id: 3, name: 'Teacher' })
     }
     if(action.payload?.includes(4)) {
      state.organizationRoles.push({ id: 4, name: 'Guardian' })
    }
    if(action.payload?.includes(5)) {
      state.organizationRoles.push({ id: 5, name: 'Student' })
    }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationRoles.pending, (state, action) => {
        state.RolesButtonSpinner = true
      }).addCase(getOrganizationRoles.fulfilled, (state, action) => {
        state.organizationRoles = action.payload.data
        state.RolesButtonSpinner = false
      }).addCase(getOrganizationRoles.rejected, (state, action) => {
        state.RolesButtonSpinner = false
      })

      .addCase(createRoles.pending, (state, action) => {
        state.RolesButtonSpinner = true;
      })
      .addCase(createRoles.fulfilled, (state, action) => {
        state.RolesButtonSpinner = false;
        state.RolesErrors = {}
      })
      .addCase(createRoles.rejected, (state, action) => {
        state.RolesShowMessage = true; // Set the showMessage flag to display the errors
        state.RolesButtonSpinner = false;
        state.RolesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getRoles.pending, (state, action) => {
        state.RolesButtonSpinner = true;
      }).addCase(getRoles.fulfilled, (state, action) => {
        state.RolesButtonSpinner = false;
        state.RolesResult = action.payload;
      }).addCase(getRoles.rejected, (state, action) => {
        state.RolesButtonSpinner = false;
      });
  },
});

export const { 
  RolesAddDrawerStatus, 
  RolesEditWithDrawerStatus,
  pushTeacherGuardianRoles
} = manageRolesSlice.actions;

export default manageRolesSlice.reducer;
