import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CHARGES_CATEGORIES, CHARGES_CATEGORIES_API_URL, GET_ALL_CHARGES_CATEGORIES_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ChargesCategoriesErrors: {},
  permission: permission?.charges_categories ?? [],
  ChargesCategoriesShowMessage: null,
  ChargesCategoriesResult: [],
  ChargesCategoriesButtonSpinner: false,
  ChargesCategoriesTableLoading: true,
  ChargesCategoriesAddDrawer: false,
  ChargesCategoriesEditData: [],
  ChargesCategoriesButtonAndModelLabel: setLocale('charge_category.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewChargesCategoriesData: [],
  ViewChargesCategoriesLoader: true,
  DrawerStatus: 0,
};

export const createChargesCategories = createAsyncThunk(
  "createChargesCategories",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CHARGES_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getChargesCategories = createAsyncThunk(
  "getChargesCategories",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CHARGES_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewChargesCategories = createAsyncThunk(
  "viewChargesCategories",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CHARGES_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteChargesCategories = createAsyncThunk(
  "deleteChargesCategories",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CHARGES_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageChargesCategoriesSlice = createSlice({
  name: CHARGES_CATEGORIES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ChargesCategoriesErrors = {};
    },
    ChargesCategoriesAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.CourseButtonSpinner = false;
      }
      // state.ChargesCategoriesErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ChargesCategoriesAddDrawer = action.payload.status;
      state.ChargesCategoriesEditData = [];
      state.ChargesCategoriesButtonAndModelLabel = setLocale('charge_category.add');
    },
    ChargesCategoriesEditWithDrawerStatus: (state, action) => {
      // state.ChargesCategoriesErrors = {};
      state.ChargesCategoriesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ChargesCategoriesEditData = action.payload.data;
      state.ChargesCategoriesButtonAndModelLabel = setLocale('charge_category.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChargesCategories.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ChargesCategoriesButtonSpinner = true;
      }).addCase(createChargesCategories.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ChargesCategoriesButtonSpinner = false;
        state.ChargesCategoriesErrors = {}
      }).addCase(createChargesCategories.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ChargesCategoriesShowMessage = true;
        state.ChargesCategoriesButtonSpinner = false;
        state.ChargesCategoriesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getChargesCategories.pending, (state, action) => {
        state.ChargesCategoriesButtonSpinner = true;
        state.ChargesCategoriesTableLoading = true;
      }).addCase(getChargesCategories.fulfilled, (state, action) => {
        state.ChargesCategoriesButtonSpinner = false;
        state.ChargesCategoriesTableLoading = false;
        state.ChargesCategoriesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getChargesCategories.rejected, (state, action) => {
        state.ChargesCategoriesShowMessage = true; // Set the showMessage flag to display the errors
        state.ChargesCategoriesButtonSpinner = false;
        state.ChargesCategoriesTableLoading = false;
        state.ChargesCategoriesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewChargesCategories.pending, (state, action) => {
        state.ViewChargesCategoriesLoader = true;
        state.ViewChargesCategoriesData = [];
      }).addCase(viewChargesCategories.fulfilled, (state, action) => {
        state.ViewChargesCategoriesLoader = false;
        state.ViewChargesCategoriesData = action.payload;
      }).addCase(viewChargesCategories.rejected, (state, action) => {
        state.ViewChargesCategoriesLoader = false;
        state.ViewChargesCategoriesData = [];
      })
      .addCase(deleteChargesCategories.pending, (state, action) => {

      }).addCase(deleteChargesCategories.fulfilled, (state, action) => {

      }).addCase(deleteChargesCategories.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, ChargesCategoriesAddDrawerStatus, ChargesCategoriesEditWithDrawerStatus, updateSortFilters } = manageChargesCategoriesSlice.actions;

export default manageChargesCategoriesSlice.reducer;
