import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {MANAGE_ROUTES, MANAGE_ROUTES_API_URL, GET_ALL_MANAGE_ROUTES_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import moment from 'moment';

const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ManageRoutesErrors       : {},
  permission: permission?.manage_routes ?? [],
  ManageRoutesShowMessage  : null,
  ManageRoutesResult       : [],
  ManageRoutesButtonSpinner : false,
  ManageRoutesTableLoading : true,
  ManageRoutesAddDrawer    : false,
  ManageRoutesEditData     : [],
  ManageRoutesButtonAndModelLabel  : setLocale('routes.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewManageRoutesData: [],
    ViewManageRoutesLoader: true,
    DrawerStatus: 0,
};

export const createManageRoutes = createAsyncThunk(
    "createManageRoutes",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MANAGE_ROUTES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageRoutes = createAsyncThunk(
    "getManageRoutes",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MANAGE_ROUTES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewManageRoutes = createAsyncThunk(
  "viewManageRoutes",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MANAGE_ROUTES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteManageRoutes = createAsyncThunk(
  "deleteManageRoutes",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MANAGE_ROUTES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageManageRoutesSlice = createSlice({
  name: MANAGE_ROUTES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ManageRoutesErrors = {};
    },
    ManageRoutesAddDrawerStatus: (state, action) => {
      // state.ManageRoutesErrors      = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageRoutesAddDrawer   = action.payload.status;
      state.ManageRoutesEditData    = [];
      state.ManageRoutesButtonAndModelLabel = setLocale('routes.add');
    },
    ManageRoutesEditWithDrawerStatus: (state, action) => {
      // state.ManageRoutesErrors = {};
      state.ManageRoutesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageRoutesEditData = {
        ...action.payload.data,
        pickup_slot_from_to: [
          moment(action.payload.data.pickup_slot_from, 'HH:mm:ss'),
          moment(action.payload.data.pickup_slot_to, 'HH:mm:ss'),
        ],
        drpoff_slot_from_to: [
          moment(action.payload.data.dropoff_slot_from, 'HH:mm:ss'),
          moment(action.payload.data.dropoff_slot_to, 'HH:mm:ss'),
        ],
      };
      state.ManageRoutesButtonAndModelLabel = setLocale('routes.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManageRoutes.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ManageRoutesButtonSpinner = true;
      }).addCase(createManageRoutes.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ManageRoutesButtonSpinner = false;
        state.ManageRoutesErrors = {}
      }).addCase(createManageRoutes.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ManageRoutesShowMessage = true;
        state.ManageRoutesButtonSpinner = false;
        state.ManageRoutesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getManageRoutes.pending, (state, action) => {
        state.ManageRoutesButtonSpinner = true;
        state.ManageRoutesTableLoading = true;
      }).addCase(getManageRoutes.fulfilled, (state, action) => {
        state.ManageRoutesButtonSpinner = false;
        state.ManageRoutesTableLoading = false;
        state.ManageRoutesResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getManageRoutes.rejected, (state, action) => {
        state.ManageRoutesShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageRoutesButtonSpinner = false;
        state.ManageRoutesTableLoading = false;
        state.ManageRoutesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewManageRoutes.pending, (state, action) => {
        state.ViewManageRoutesLoader = true;
        state.ViewManageRoutesData = [];
      }).addCase(viewManageRoutes.fulfilled, (state, action) => {
        state.ViewManageRoutesLoader = false;
        state.ViewManageRoutesData = action.payload;
      }).addCase(viewManageRoutes.rejected, (state, action) => {
        state.ViewManageRoutesLoader = false;
        state.ViewManageRoutesData = [];
      })
      .addCase(deleteManageRoutes.pending, (state, action) => {

      }).addCase(deleteManageRoutes.fulfilled, (state, action) => {

      }).addCase(deleteManageRoutes.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, ManageRoutesAddDrawerStatus, ManageRoutesEditWithDrawerStatus, updateSortFilters } = manageManageRoutesSlice.actions;

export default manageManageRoutesSlice.reducer;
