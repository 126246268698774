import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {MARITAL_STATUS, MARITAL_STATUS_API_URL, GET_ALL_MARITAL_STATUS_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  MaritalStatusErrors       : {},
  permission: permission?.marital_status ?? [],
  MaritalStatusShowMessage  : null,
  MaritalStatusResult       : [],
  MaritalStatusButtonSpinner : false,
  MaritalStatusTableLoading : true,
  MaritalStatusAddDrawer    : false,
  MaritalStatusEditData     : [],
  MaritalStatusButtonAndModelLabel  : setLocale('marital_status.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewMaritalStatusData: [],
    ViewMaritalStatusLoader: true,
    DrawerStatus: 0,
    
};

export const createMaritalStatus = createAsyncThunk(
    "createMaritalStatus",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MARITAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getMaritalStatus = createAsyncThunk(
    "getMaritalStatus",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MARITAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewMaritalStatus = createAsyncThunk(
  "viewMaritalStatus",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MARITAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteMaritalStatus = createAsyncThunk(
  "deleteMaritalStatus",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MARITAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageMaritalStatusSlice = createSlice({
  name: MARITAL_STATUS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.MaritalStatusErrors = {};
    },
    MaritalStatusAddDrawerStatus: (state, action) => {
      // state.MaritalStatusErrors      = {};
      state.MaritalStatusAddDrawer   = action.payload.status
      state.DrawerStatus = action.payload.errorStatus;;
      state.MaritalStatusEditData    = [];
      state.MaritalStatusButtonAndModelLabel = setLocale('marital_status.add');
    },
    MaritalStatusEditWithDrawerStatus: (state, action) => {
      // state.MaritalStatusErrors = {};
      state.MaritalStatusAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.MaritalStatusEditData = action.payload.data;
      state.MaritalStatusButtonAndModelLabel = setLocale('marital_status.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMaritalStatus.pending, (state, action) => {
        state.DrawerStatus = 0
        state.MaritalStatusButtonSpinner = true;
      }).addCase(createMaritalStatus.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.MaritalStatusButtonSpinner = false;
        state.MaritalStatusErrors = {}
      }).addCase(createMaritalStatus.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.MaritalStatusShowMessage = true;
        state.MaritalStatusButtonSpinner = false;
        state.MaritalStatusErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getMaritalStatus.pending, (state, action) => {
        state.MaritalStatusButtonSpinner = true;
        state.MaritalStatusTableLoading = true;
      }).addCase(getMaritalStatus.fulfilled, (state, action) => {
        state.MaritalStatusButtonSpinner = false;
        state.MaritalStatusTableLoading = false;
        state.MaritalStatusResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getMaritalStatus.rejected, (state, action) => {
        state.MaritalStatusShowMessage = true; // Set the showMessage flag to display the errors
        state.MaritalStatusButtonSpinner = false;
        state.MaritalStatusTableLoading = false;
        state.MaritalStatusErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewMaritalStatus.pending, (state, action) => {
        state.ViewMaritalStatusLoader = true;
        state.ViewMaritalStatusData = [];
      }).addCase(viewMaritalStatus.fulfilled, (state, action) => {
        state.ViewMaritalStatusLoader = false;
        state.ViewMaritalStatusData = action.payload;
      }).addCase(viewMaritalStatus.rejected, (state, action) => {
        state.ViewMaritalStatusLoader = false;
        state.ViewMaritalStatusData = [];
      })
      .addCase(deleteMaritalStatus.pending, (state, action) => {

      }).addCase(deleteMaritalStatus.fulfilled, (state, action) => {

      }).addCase(deleteMaritalStatus.rejected, (state, action) => {

      });
  },
});

export const {onCloseError,setColumnSearch, MaritalStatusAddDrawerStatus, MaritalStatusEditWithDrawerStatus, updateSortFilters } = manageMaritalStatusSlice.actions;

export default manageMaritalStatusSlice.reducer;
