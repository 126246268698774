import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  BUDGET_ALLOCATION, 
  BUDGET_ALLOCATION_API_URL, 
  GET_ALL_BUDGET_ALLOCATION_API_URL
} from 'constants/AppConstants';
import {
  GET_ALL_PURCHASE_REQUESTS_FOR_ADMIN,
  GET_TEACHER_GRADE_COURSES,
  GET_TEACHER_ALLOCATED_BUDGET,
  SAVE_TEACHER_BUDGET,
  GET_ALL_BUDGET_ALLOCATIONS,
  GET_TEACHER_BUDGET,
  UPDATE_BUDGET_ITEM
} from 'constants/budget-allocation/index.js';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  BudgetAllocationErrors       : {},
  permission: permission?.budget_allocation ?? [],
  BudgetAllocationShowMessage  : null,
  BudgetAllocationResult       : [],
  BudgetAllocationButtonSpinner : false,
  BudgetAllocationTableLoading : true,
  BudgetAllocationAddDrawer    : false,
  BudgetAllocationEditData     : [],
  BudgetAllocationButtonAndModelLabel  : setLocale('budgetallocation.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewBudgetAllocationData: [],
    ViewBudgetAllocationLoader: true,
    DrawerStatus: 0,  
    teacherOrganizationGradeLevels: [], 

    teacherAllocatedBudget: [], 
    allocatedBudgetFilter: null,
    saveBudgetBtnLoader: false,

    allocatedBudgetView:[],
    allocatedBudgetViewDrawer: false,
    teacherBudgetData:[],

};

export const updateBudgetItem = createAsyncThunk("updateBudgetItem", async (data) => {
  try {
    return await CommonService.postData(data, UPDATE_BUDGET_ITEM);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});
export const getTeacherBudget = createAsyncThunk("getTeacherBudget", async (data) => {
    try {
      return await CommonService.postData(data, GET_TEACHER_BUDGET);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});
export const saveTeacherBudgetAllocation = createAsyncThunk("saveTeacherBudgetAllocation", async (data) => {
  try {
    return await CommonService.postData(data, SAVE_TEACHER_BUDGET);    
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }  
})
export const fetchTeacherAllocatedBudget = createAsyncThunk("fetchTeacherAllocatedBudget", async (data) => {
    try {
      return await CommonService.postData(data, GET_TEACHER_ALLOCATED_BUDGET);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});
export const getTeacherGradeCourses = createAsyncThunk("getTeacherGradeCourses", async (data) => {
    try {
      return await CommonService.postData(data, GET_TEACHER_GRADE_COURSES);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});
export const createBudgetAllocation = createAsyncThunk("createBudgetAllocation", async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, BUDGET_ALLOCATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getBudgetAllocations = createAsyncThunk("getBudgetAllocations", async (data) => {
    try {
      return await CommonService.getAllPost(data, GET_ALL_BUDGET_ALLOCATIONS);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
    }
  }
);

export const viewBudgetAllocation = createAsyncThunk(
  "viewBudgetAllocation",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, BUDGET_ALLOCATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteBudgetAllocation = createAsyncThunk(
  "deleteBudgetAllocation",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, BUDGET_ALLOCATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageBudgetAllocationSlice = createSlice({
  name: BUDGET_ALLOCATION,
  initialState,
  reducers: {
    setAllocatedBudgetView: (state, action) => {
      state.allocatedBudgetViewDrawer = action.payload.status;
      state.allocatedBudgetView = action.payload.data;      
    },
    setAllocatedBudgetFilter: (state, action) => {
      state.allocatedBudgetFilter = action.payload;
    },
    setTeacherAllocatedBudget: (state, action) => {
      state.teacherAllocatedBudget = action.payload;
    },
    onCloseError: (state, action) => {
      state.RelationshipErrors = {};
    },
    BudgetAllocationAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.BudgetAllocationButtonSpinner = false;
      }
      state.BudgetAllocationAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.BudgetAllocationEditData    = [];
      state.BudgetAllocationButtonAndModelLabel = setLocale('budgetallocation.add');
    },
    BudgetAllocationEditWithDrawerStatus: (state, action) => {
      state.BudgetAllocationAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.BudgetAllocationEditData = action.payload.data;
      state.BudgetAllocationButtonAndModelLabel = setLocale('budgetallocation.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBudgetItem.pending, (state, action) => {
        state.BudgetAllocationButtonSpinner = true;
      }).addCase(updateBudgetItem.fulfilled, (state, action) => {
        state.BudgetAllocationButtonSpinner = false;
        state.allocatedBudgetView = action.payload.data;        
      }).addCase(updateBudgetItem.rejected, (state, action) => {
        state.BudgetAllocationButtonSpinner = false;
      })
      .addCase(getTeacherBudget.pending, (state, action) => {
        state.BudgetAllocationTableLoading = true;
      }).addCase(getTeacherBudget.fulfilled, (state, action) => {
        state.BudgetAllocationTableLoading = false;
        state.teacherBudgetData = action.payload.data;
      }).addCase(getTeacherBudget.rejected, (state, action) => {
        state.BudgetAllocationTableLoading = false;
      })
      .addCase(saveTeacherBudgetAllocation.pending, (state, action) => {
        state.saveBudgetBtnLoader = true;        
      }).addCase(saveTeacherBudgetAllocation.fulfilled, (state, action) => {
        state.saveBudgetBtnLoader = false;
      }).addCase(saveTeacherBudgetAllocation.rejected, (state, action) => {
        state.saveBudgetBtnLoader = false;
      })
      .addCase(fetchTeacherAllocatedBudget.pending, (state, action) => {
        state.BudgetAllocationButtonSpinner = true;
        state.teacherAllocatedBudget = [];
      }).addCase(fetchTeacherAllocatedBudget.fulfilled, (state, action) => {
        state.BudgetAllocationButtonSpinner = false;
        state.teacherAllocatedBudget = action.payload;
      }).addCase(fetchTeacherAllocatedBudget.rejected, (state, action) => {
        state.BudgetAllocationButtonSpinner = false;
      })
      .addCase(getTeacherGradeCourses.pending, (state, action) => {        
        state.teacherOrganizationGradeLevels = [];
      }).addCase(getTeacherGradeCourses.fulfilled, (state, action) => {        
        state.teacherOrganizationGradeLevels = action.payload;
      }).addCase(getTeacherGradeCourses.rejected, (state, action) => {        
      })
      .addCase(createBudgetAllocation.pending, (state, action) => {
        state.DrawerStatus = 0
        state.BudgetAllocationButtonSpinner = true;
      }).addCase(createBudgetAllocation.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.BudgetAllocationButtonSpinner = false;
        state.BudgetAllocationErrors = {}
      }).addCase(createBudgetAllocation.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.BudgetAllocationShowMessage = true;
        state.BudgetAllocationButtonSpinner = false;
        state.BudgetAllocationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getBudgetAllocations.pending, (state, action) => {        
        state.BudgetAllocationTableLoading = true;
      }).addCase(getBudgetAllocations.fulfilled, (state, action) => {
        state.BudgetAllocationTableLoading = false;
        state.BudgetAllocationResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getBudgetAllocations.rejected, (state, action) => {
        state.BudgetAllocationShowMessage = true; 
        state.BudgetAllocationTableLoading = false;
        state.BudgetAllocationErrors = JSON.parse(action.error.message);
      })
      .addCase(viewBudgetAllocation.pending, (state, action) => {
        state.ViewBudgetAllocationLoader = true;
        state.ViewBudgetAllocationData = [];
      }).addCase(viewBudgetAllocation.fulfilled, (state, action) => {
        state.ViewBudgetAllocationLoader = false;
        state.ViewBudgetAllocationData = action.payload;
      }).addCase(viewBudgetAllocation.rejected, (state, action) => {
        state.ViewBudgetAllocationLoader = false;
        state.ViewBudgetAllocationData = [];
      })
      .addCase(deleteBudgetAllocation.pending, (state, action) => {
        state.BudgetAllocationTableLoading = true;
      }).addCase(deleteBudgetAllocation.fulfilled, (state, action) => {
        state.BudgetAllocationTableLoading = false;
      }).addCase(deleteBudgetAllocation.rejected, (state, action) => {
        state.BudgetAllocationTableLoading = false;
      });
  },
});

export const { 
  onCloseError, 
  setColumnSearch, 
  BudgetAllocationAddDrawerStatus, 
  BudgetAllocationEditWithDrawerStatus, 
  updateSortFilters,
  setTeacherAllocatedBudget,
  setAllocatedBudgetFilter,
  setAllocatedBudgetView
} = manageBudgetAllocationSlice.actions;

export default manageBudgetAllocationSlice.reducer;
