import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_API_URL,
  GET_ALL_DOCUMENT_TYPE_API_URL,
  GET_ALL_DOCUMENT_CATEGORY_API_URL,
} from "constants/AppConstants";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(
  localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE)
);

export const initialState = {
  DocumentTypeErrors: {},
  permission: permission?.document_type ?? [],
  DocumentTypeShowMessage: null,
  DocumentTypeResult: [],
  DocumentCategoryLoader: [],
  DocumentCategoryResult: [],
  DocumentTypeButtonSpinner: false,
  DocumentTypeTableLoading: true,
  DocumentTypeAddDrawer: false,
  DocumentTypeEditData: [],
  DocumentTypeButtonAndModelLabel: setLocale("documenttype.add"),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewDocumentTypeData: [],
  ViewDocumentTypeLoader: true,
  DrawerStatus: 0,
};

export const createDocumentType = createAsyncThunk(
  "createDocumentType",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        DOCUMENT_TYPE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getDocumentType = createAsyncThunk(
  "getDocumentType",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_ALL_DOCUMENT_TYPE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getDocumentCategory = createAsyncThunk(
  "getDocumentCategory",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_ALL_DOCUMENT_CATEGORY_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewDocumentType = createAsyncThunk(
  "viewDocumentType",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, DOCUMENT_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteDocumentType = createAsyncThunk(
  "deleteDocumentType",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(
        data,
        DOCUMENT_TYPE_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageDocumentTypeSlice = createSlice({
  name: DOCUMENT_TYPE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RelationshipErrors = {};
    },
    DocumentTypeAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.DocumentTypeButtonSpinner = false;
      }
      state.DocumentTypeAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.DocumentTypeEditData = [];
      state.DocumentTypeButtonAndModelLabel = setLocale("documenttype.add");
    },
    DocumentTypeEditWithDrawerStatus: (state, action) => {
      state.DocumentTypeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.DocumentTypeEditData = action.payload.data;
      state.DocumentTypeButtonAndModelLabel = setLocale("documenttype.edit");
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDocumentType.pending, (state, action) => {
        state.DrawerStatus = 0;
        state.DocumentTypeButtonSpinner = true;
      })
      .addCase(createDocumentType.fulfilled, (state, action) => {
        state.DrawerStatus = 0;
        state.DocumentTypeButtonSpinner = false;
        state.DocumentTypeErrors = {};
      })
      .addCase(createDocumentType.rejected, (state, action) => {
        state.DrawerStatus = 1;
        state.DocumentTypeShowMessage = true;
        state.DocumentTypeButtonSpinner = false;
        state.DocumentTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getDocumentType.pending, (state, action) => {
        state.DocumentTypeButtonSpinner = true;
        state.DocumentTypeTableLoading = true;
      })
      .addCase(getDocumentType.fulfilled, (state, action) => {
        state.DocumentTypeButtonSpinner = false;
        state.DocumentTypeTableLoading = false;
        state.DocumentTypeResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page,
        };
      })
      .addCase(getDocumentType.rejected, (state, action) => {
        state.DocumentTypeShowMessage = true; // Set the showMessage flag to display the errors
        state.DocumentTypeButtonSpinner = false;
        state.DocumentTypeTableLoading = false;
        state.DocumentTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getDocumentCategory.pending, (state, action) => {
        state.DocumentCategoryLoader = true;
        state.DocumentCategoryResult = [];
      })
      .addCase(getDocumentCategory.fulfilled, (state, action) => {
        state.DocumentCategoryLoader = false;
        state.DocumentCategoryResult = action.payload;
      })
      .addCase(getDocumentCategory.rejected, (state, action) => {
        state.DocumentCategoryLoader = false;
        state.DocumentCategoryResult = [];
      })
      .addCase(viewDocumentType.pending, (state, action) => {
        state.ViewDocumentTypeLoader = true;
        state.ViewDocumentTypeData = [];
      })
      .addCase(viewDocumentType.fulfilled, (state, action) => {
        state.ViewDocumentTypeLoader = false;
        state.ViewDocumentTypeData = action.payload;
      })
      .addCase(viewDocumentType.rejected, (state, action) => {
        state.ViewDocumentTypeLoader = false;
        state.ViewDocumentTypeData = [];
      })
      .addCase(deleteDocumentType.pending, (state, action) => {
        state.DocumentTypeTableLoading = true;
      })
      .addCase(deleteDocumentType.fulfilled, (state, action) => {
        state.DocumentTypeTableLoading = false;
      })
      .addCase(deleteDocumentType.rejected, (state, action) => {
        state.DocumentTypeTableLoading = false;
      });
  },
});

export const {
  onCloseError,
  setColumnSearch,
  DocumentTypeAddDrawerStatus,
  DocumentTypeEditWithDrawerStatus,
  updateSortFilters,
} = manageDocumentTypeSlice.actions;

export default manageDocumentTypeSlice.reducer;
